import * as Sentry from "@sentry/react";
import { axiosSparInstance } from "./axios";
import axiosAuthInstance from "./axiosAuth";

const base_url = process.env.REACT_APP_SPAR_ENDPOINT;

export const createPromoRule = async (promoData) => {
  try {
    let url = `/app/rules-engine`;
    const body = {
      "route": `/promo-rule`,
      "method": "POST",
      "body" : promoData
    }

    const {data}  = await axiosAuthInstance.post(url,body);
    return data.data;
  } catch (ex) {
    if (ex.status == 409) {
      return ex.response.data;
    }
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getPromoRuleList = async (ruleType, ruleSearchText, page, rowsPerPage) => {
  try {

    let url = `/app/rules-engine`;
    let requestParams =
      ruleType === "inactive"
        ? `status=${ruleType}&limit=${rowsPerPage}&page=${page}`
        : `ruleType=${ruleType}&status=active&limit=${rowsPerPage}&page=${page}`;

    if (ruleSearchText) {
      requestParams += `&searchText=${ruleSearchText}`;
    }

    const body = {
      "route": `/promo-rule/list`,
      "method": "GET",
      "requestParams" : requestParams
    }
    const {data}  = await axiosAuthInstance.post(url,body);

    return data.data;
  } catch (ex) {
    if (ex.status == 409) {
      return ex.response.data;
    }
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getPromoRuleData = async (id) => {
  try {
    let url = `/app/rules-engine`;
    const body = {
      "route": `/promo-rule/${id}`,
      "method": "GET"
    }
    const { data } = await axiosAuthInstance.post(url,body);

    return data.data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const updateRuleStatus = async ({ promoRuleId, ruleStatus }) => {
  try {
    let url = `/app/rules-engine`;
    const body = {
      "route": `/promo-rule/${promoRuleId}/${ruleStatus}`,
      "method": "GET"
    }
    const { data } = await axiosAuthInstance.post(url,body);
    return data.data;
  } catch (ex) {
    if (ex.status == 409) {
      return ex.response.data;
    }
    console.error(ex);
    Sentry.captureException(ex);
  }
};
