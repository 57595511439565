import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup
    .string("Enter your name")
    .matches(/^[a-zA-Z\s]*$/, "Invalid name")
    .required("Name is required"),
  cardNumber: yup
    .string("Enter your card number")
    .matches(
      /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
      "Invalid card number"
    )
    .required("Card number is required"),
  expiryDate: yup
    .string("Enter expiry date")
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{4})$/, "Invalid expiry date")
    .required("Expiry date is required"),
  cvv: yup
    .string("Enter CVV")
    .matches(/^[0-9]{3,4}$/, "Invalid CVV")
    .required("CVV is required"),
  topupAmount: yup
    .string("Enter topup amount")
    .matches(/^[0-9]*$/, "Invalid topup amount")
    .required("Topup amount is required"),
});

const TopupDialog = ({ open, handleClose, handleConfirm, topupAmount, handleTopupChange, creditCardDetails, setCreditCardDetails }) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setCreditCardDetails({
      name: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    });
  }, [open]);

  const handleChange = (prop) => (event) => {
    let { value } = event.target;
    if (prop === "cardNumber" || prop === "cvv") {
      value = value.replace(/\D/g, ""); // remove non-digit characters
    }
    if (prop === "topupAmount") {
      handleTopupChange(event);
    } else {
      setCreditCardDetails((prevDetails) => ({
        ...prevDetails,
        [prop]: value,
      }));
    }
    // Validation
    validationSchema
      .validateAt(prop, { [prop]: value })
      .then(() => {
        setErrors((prevErrors) => ({ ...prevErrors, [prop]: undefined }));
      })
      .catch((err) => {
        setErrors((prevErrors) => ({ ...prevErrors, [prop]: err.errors[0] }));
      });
  };

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <DialogTitle
        sx={{
          bgcolor: "primary.main",
          color: "primary.contrastText",
          mb: 1,
          py: 2,
          pb: 1,
        }}
      >
        Top Up Your Account
      </DialogTitle>
      <DialogContent
        sx={{
          mb: 1,
        }}
      >
        <Card sx={{ px: 3, py: 2, my: 2, boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.1)" }}>
          <Typography variant="h6" color="text.secondary">
            EFT Details:
          </Typography>
          <Grid
            container
            spacing={1}
            sx={{
              mx: 1,
              my: 0,
            }}
          >
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="text.secondary">
                Bank:
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Account Name:
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Account Number:
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Account Type:
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Branch Code:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color="text.secondary">
                Investec Bank Limited
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Megsapp RSA (Pty) Ltd
              </Typography>
              <Typography variant="body2" color="text.secondary">
                10012049057
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Current Account
              </Typography>
              <Typography variant="body2" color="text.secondary">
                58 01 05
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ px: 3, py: 2, my: 2, boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.1)" }}>
          <Typography variant="h6" color="text.secondary">
            Pay by Credit Card
          </Typography>
          <TextField
            sx={{
              mt: 2,
              mb: 1,
            }}
            required
            error={Boolean(errors.name)}
            autoFocus
            helperText={errors.name}
            fullWidth
            size="small"
            value={creditCardDetails.name}
            onChange={handleChange("name")}
            label="Card Holder Name"
          />
          <TextField
            sx={{
              my: 1,
            }}
            required
            error={Boolean(errors.cardNumber)}
            helperText={errors.cardNumber}
            fullWidth
            size="small"
            value={creditCardDetails.cardNumber}
            onChange={handleChange("cardNumber")}
            label="Card Number"
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                sx={{
                  my: 1,
                }}
                required
                error={Boolean(errors.expiryDate)}
                helperText={errors.expiryDate}
                fullWidth
                size="small"
                value={creditCardDetails.expiryDate}
                onChange={handleChange("expiryDate")}
                label="Expiry Date (MMYYYY)"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{
                  my: 1,
                }}
                required
                error={Boolean(errors.cvv)}
                helperText={errors.cvv}
                fullWidth
                size="small"
                value={creditCardDetails.cvv}
                onChange={handleChange("cvv")}
                label="CVV"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{
                  my: 1,
                }}
                required
                size="small"
                id="topup"
                type="text"
                fullWidth
                value={topupAmount}
                onChange={handleChange("topupAmount")}
                label="Topup Amount in Rand (ZAR)"
                InputProps={{
                  startAdornment: <Typography variant="body1">R</Typography>,
                }}
              />
            </Grid>
          </Grid>
          <DialogActions
            sx={{
              my: 0,
            }}
          >
            <Button variant="contained" onClick={handleClose} color="error">
              Cancel
            </Button>
            <Button
              disabled={
                errors.name ||
                errors.cardNumber ||
                errors.cvv ||
                errors.expiryDate ||
                !topupAmount ||
                topupAmount <= 0 ||
                creditCardDetails.name === "" ||
                creditCardDetails.cardNumber === "" ||
                creditCardDetails.cvv === "" ||
                creditCardDetails.expiryDate === ""
              }
              variant="contained"
              onClick={handleConfirm}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default TopupDialog;
