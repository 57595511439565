import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import "../../utils/highlight";
import ReactQuill from "react-quill";
import { EmojiBlot, ShortNameEmoji, ToolbarEmoji } from "quill-emoji";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
//
import { StyledEditor } from "./styles";
import EditorToolbar, { formats } from "./EditorToolbar";

const Editor = forwardRef(({ id = "minimal-quill", error, value, onChange, simple = false, helperText, sx, ...other }, ref) => {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            mb: 2,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />

        <ReactQuill
          ref={ref} // Forward the ref to the ReactQuill component
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write something awesome..."
          {...other}
        />
      </StyledEditor>

      {helperText && (
        <div
          style={{
            paddingTop: "1rem",
            color: "red",
            textAlign: "center",
          }}
        >
          {helperText}
        </div>
      )}
    </>
  );
});

Editor.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  error: PropTypes.bool,
  simple: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  editorRef: PropTypes.object,
};

export default Editor;
