import { axiosAuthInstance } from "./axiosAuth";
import * as Sentry from "@sentry/react";

const base_url = process.env.REACT_APP_API_ENDPOINT;

export const getIotAccountList = async (i_customer, search) => {
  /* 
  {
    "i_customer": 4161,
    "search": "2768"
}
  */
  //  const request = {
  //   "i_customer": i_customer,
  //   "search": search
  // }

  try {
    const url = `${base_url}/app/iot/account/list?i_customer=${i_customer}&search=${search}`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log(`typeof data.status_code: ${typeof status_code}`);
    // console.log(`iot-api: getIotAccountList i_customer - ${i_customer}: `, data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getIotAccountDetails = async (i_customer, msisdn) => {
  /* 
  {
    "i_customer": 4161,
    "msisdn": "2768"
}
  */
  // const request = {
  //   i_customer: i_customer,
  //   search: msisdn,
  // };
  // console.log(`request`, request);

  try {
    const url = `${base_url}/app/iot/account/list?i_customer=${i_customer}&search=${msisdn}`;
    // const url = `${base_url}/app/iot/account/list?i_customer=4161&search=27639011065`;

    const { data, status_code } = await axiosAuthInstance.get(url);

    // console.log(`typeof data.status_code: ${typeof status_code}`);
    // console.log("iot-api: getIotAccountDetails", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getAccountBalance = async (i_account) => {
  // const url = `${base_url}/app/iot/account/balance?i_account=${i_account}`;
  const url = `${base_url}/app/iot/account/balance?i_account=${i_account}`;

  // const request = {
  //   i_account: i_account,
  // };

  try {
    const { data } = await axiosAuthInstance.get(url);
    // console.log("iot-api: getAccountBalance", data);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getAccountUsage = async (i_account) => {
  const url = `${base_url}/app/iot/account/usage?i_account=${i_account}`;

  // const request = {
  //   i_account: i_account,
  //   // i_account: 37,
  // };

  try {
    const { data } = await axiosAuthInstance.get(url);
    // console.log("iot-api: getAccountUsage", data);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getAccountTransactions = async (i_account) => {
  const url = `${base_url}/app/iot/account/transactions?i_account=${i_account}`;

  // const request = {
  //   i_account: i_account,
  //   // i_account: 37,
  // };

  try {
    const { data } = await axiosAuthInstance.get(url);
    // console.log("iot-api: getAccountTransactions", data);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getCustomerBalance = async (i_customer, refresh = true) => {
  const url = `${base_url}/app/iot/customer/balance?i_customer=${i_customer}&refresh=${refresh}`;

  // const request = {
  //   i_customer: i_customer,
  //   refresh: refresh,
  // };

  try {
    const { data } = await axiosAuthInstance.get(url);
    // console.log("iot-api: getCustomerBalance", data);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const topUpCustomerBalance = async (i_customer, amount, creditCardDetails) => {
  const url = `${base_url}/app/iot/customer/topup`;

  const request = {
    i_customer: i_customer,
    amount: amount,
    card_name: creditCardDetails.name,
    card_number: creditCardDetails.cardNumber,
    card_expiry_date: creditCardDetails.expiryDate,
    card_cvv: creditCardDetails.cvv,
  };

  // console.log("iot-api: topUpCustomerBalance", request);

  try {
    const { data } = await axiosAuthInstance.post(url, request);
    // console.log("iot-api: topUpCustomerBalance", data);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const topUpIoTAccount = async (msisdn, product_id) => {
  const url = `${base_url}/app/iot/account/topup`;

  const request = {
    product_id,
    msisdn,
  };

  // console.log("iot-api: topUpIoTAccount", request);

  try {
    const { data } = await axiosAuthInstance.post(url, request);
    // console.log("iot-api: topUpIoTAccount", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const updateIoTAccount = async (i_account, alias) => {
  const url = `${base_url}/app/iot/account/${i_account}`;

  const request = {
    alias,
  };

  // console.log("iot-api: updateIoTAccount", request);

  try {
    const { data } = await axiosAuthInstance.put(url, request);
    // console.log("iot-api: updateIoTAccount", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getProductType = async (type) => {
  try {
    let url = `${base_url}/app/iot/product/type`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getProductList = async (type) => {
  try {
    let url = `${base_url}/app/iot/product/list`;
    if (type) url = `${base_url}/app/iot/product/list?type=${type}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getCustomerXdrs = async (i_customer) => {
  try {
    let url = `${base_url}/app/iot/customer/xdrs?i_customer=${i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const createIotRule = async (ruleData) =>{
  try{
    let url = `${base_url}/app/iot/rule`;
    const { data } = await axiosAuthInstance.post(url,ruleData);
    return data;
  }catch(ex){
    console.error(ex);
    Sentry.captureException(ex);
  }
}

export const getIotRules = async(i_customer) =>{
  try {
    let url = `${base_url}/app/iot/rules/${i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
}

export const getDistributorSimCardsIot = async(i_customer) =>{
  try {
    let url = `${base_url}/app/iot/rules/sim-cards/${i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
}

export const getIotCustomers = async () =>{
  try{
    let url = `${base_url}/app/iot/customer/list`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  }catch(ex){
    console.error(ex);
    Sentry.captureException(ex);
  }
}

export const allocateIotRules = async(ruleData) =>{
  try{
    let url = `${base_url}/app/iot/rules/allocation`;
    const { data } = await axiosAuthInstance.post(url,ruleData);
    return data;
  }catch(ex){
    console.error(ex);
    Sentry.captureException(ex);
  }
}

export const getRuleSimCardData = async({ limit , page , msisdnSearchText , distributor }) =>{
  try {
    let url = `${base_url}/app/iot/account/sim-cards/?page=${page}&limit=${limit}`;

    if(msisdnSearchText){
      url += `&msisdnSearchText=${msisdnSearchText}`
    }
    if(distributor){
      url += `&distributor=${distributor}`
    }
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
}