import PropTypes from "prop-types";
import { useEffect, useState, useRef, useLayoutEffect } from "react";

// @mui
import { Button, Card, Checkbox, FormControlLabel, Grid, Stack, Switch, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "react-redux";
import { updateProductGroupMapping, updateProductStatus } from "src/api/megs-api-api";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useNavigate } from "react-router-dom";
import ChatPage from "./components/ChatPage";

// ----------------------------------------------------------------------

EscalationDetails.propTypes = {
  productData: PropTypes.object,
};

export default function EscalationDetails({ escalationData, loading }) {
  const { title, description, priority, app_user, messages, attachments, iccid } = escalationData;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ py: 3, px: 3 }}>
            <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
              Escalation Details
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2">App User</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {app_user}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2">Title</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2">Description</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {description}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2">Priority</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {priority}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2">ICCID/Sim Serial Number</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                  {iccid ? iccid : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card sx={{ py: 3, px: 3 }}>
            <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
              Chat Details
            </Typography>
            <ChatPage chatDetails={messages} attachments={attachments} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
