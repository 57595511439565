import PropTypes from "prop-types";
// @mui
import { Link, TableRow, TableCell, Typography, Button, IconButton, Tooltip } from "@mui/material";
// import check and cross icons from material icons
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Label from "src/components/label";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { ConfirmationDialog } from "src/components/dialog/ConfirmationDialog";
import { deleteAdminUser, deleteAppUserRole } from "src/api/megs-api-api";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "src/redux/store";

// ----------------------------------------------------------------------

DistributorReportTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function DistributorReportTableRow({ row, selected, onViewRow, selectedDistributor }) {
  const { name, date, total_customers, month_customers, total_revenue, total_commission, total_commission_less_vat, report_link, po_link } = row;
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectedRole = useSelector((state) => state.auth.selectedRole);

  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  const handleReportDownload = (event) => {
    event.stopPropagation();
    if (report_link) {
      const link = document.createElement("a");
      link.href = report_link;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handlePODownload = (event) => {
    event.stopPropagation();
    if (po_link) {
      window.open(po_link, "_blank");
    }
  };

  return (
    <>
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell
          onClick={(event) => {
            event.stopPropagation();
          }}
          sx={{
            cursor: "text",
          }}
        >
          <Typography variant="subtitle2" noWrap>
            <Tooltip title={name}>{truncateString(name, 60)}</Tooltip>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {new Date(date).toLocaleDateString("en-ZA", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {total_customers !== null ? total_customers : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {month_customers !== null ? month_customers : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {total_revenue !== null ? total_revenue : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {total_commission !== null ? total_commission : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {total_commission_less_vat !== null ? total_commission_less_vat : "-"}
          </Typography>
        </TableCell>

        {/* <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {month_customers}
          </Typography>
        </TableCell> */}

        {/* <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {is_active ? <CheckIcon color="success" /> : <ClearIcon color="error" />}
          </Typography>
        </TableCell> */}

        <TableCell align="left">
          <IconButton onClick={handleReportDownload} disabled={!report_link}>
            <Iconify icon="ic:baseline-download" />
          </IconButton>
        </TableCell>

        <TableCell align="left">
          <IconButton onClick={handlePODownload} disabled={!po_link}>
            <Iconify icon="ic:baseline-download" />
          </IconButton>
        </TableCell>
      </Link>
    </>
  );
}
