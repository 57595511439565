import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { useSelector } from "react-redux";
import { getSubDistributor } from "src/api/distributor-api";
import { useEffect, useState } from "react";
import Label from "src/components/label";
import { fontSize } from "@mui/system";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  // console.log("NavAccount.js: selectedRole: ", selectedRole);

  const [subDistributor, setSubDistributor] = useState();
  useEffect(() => {
    if (selectedRole.role === "sub-distributor" || selectedRole.role === "agent") {
      async function fetchSubDistributor() {
        try {
          const response = await getSubDistributor(selectedRole.i_customer, selectedRole.sub_distributor_id);
          if (response.status_code === 1001) {
            setSubDistributor(response.data);
          }
        } catch (error) {
          console.error("Error fetching sub distributor:", error);
        }
      }
      fetchSubDistributor();
    }
  }, [selectedRole.i_customer, selectedRole.sub_distributor_id, selectedRole.role]);

  return (
    <Link component={RouterLink} to={PATH_DASHBOARD.user.account} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar alt={selectedRole.customer_name} name={""}>
          <Icon icon="eva:person-fill" />
        </CustomAvatar>

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {/* if user.type is admin show username */}
            {user?.type === "admin" ? user?.username : selectedRole.customer_name}
          </Typography>
          {subDistributor && <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>{subDistributor.name}</Typography>}
          {user?.type !== 'sub-distributor' && <Label
            variant="soft"
            color={
              (user?.type === "admin" && "primary") ||
              (user?.type === "distributor" && "secondary") ||
              (user?.type === "sub-distributor" && "success") ||
              (user?.type === "support_agent" && "success") ||
              (user?.type === "iot" && "info") ||
              (user?.type === "agent" && "warning") ||
              "info"
            }
            sx={{ mt: 0.5, fontSize: '0.7rem', my: 0 }}
          >
            {user?.type === "support_agent" ? "Support Agent" : user?.type}
          </Label>}
        </Box>
      </StyledRoot>
    </Link>
  );
}
