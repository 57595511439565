import { Button, Container, Grid } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getDistributorsByLevels } from "src/api/distribution-api";
import Iconify from "src/components/iconify";
import { useSettingsContext } from "src/components/settings";
import AddLeveledSubDistributor from "src/sections/@dashboard/sub-distributor-levels/AddLeveledSubDistributor";
import LeveledSubDistributorList from "src/sections/@dashboard/sub-distributor-levels/LeveledSubDistributorList";
import SubDistributorList from "src/sections/@dashboard/sub-distributor-levels/SubDistributorList";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { PATH_DASHBOARD } from "../../routes/paths";

const fetchDistributorsByLevels = async (distributor_i_customer, level, id) => {
  try {
    const response = await getDistributorsByLevels(distributor_i_customer, level, id);
    return response.data.parent;
  } catch (error) {
    console.error("Error fetching distributors:", error);
    return null;
  }
};

const SubDistributorsLevelsPage = () => {
  const { themeStretch } = useSettingsContext();
  const [showAddAppUser, setShowAddAppUser] = useState(false);
  const [parent, setParent] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer, distributor_id } = selectedRole; // Get distributor_id from selectedRole
  const { distributor_i_customer, id, level } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // If user is not admin and there's no level parameter, redirect to level 1
    if (selectedRole.role !== 'admin' && !level) {
      navigate(PATH_DASHBOARD.subDistributors.levels(i_customer, distributor_id, 1));
      return;
    }

    // Only fetch data if we're viewing a level or if user is admin
    if (level || selectedRole.role === 'admin') {
      const loadData = async () => {
        setIsLoading(true);
        const parentData = await fetchDistributorsByLevels(
          distributor_i_customer || i_customer, 
          level, 
          id || distributor_id
        );
        setParent(parentData);
        setIsLoading(false);
      };
      loadData();
    }
  }, [distributor_i_customer, level, id, refreshTrigger, selectedRole.role, navigate, i_customer, distributor_id]);

  const handleOpenModal = () => {
    setShowAddAppUser(true);
  };

  const handleCloseModal = () => {
    setShowAddAppUser(false);
  };

  const handleAddSuccess = useCallback(() => {
    handleCloseModal();
    setRefreshTrigger(prev => prev + 1);
  }, []);

  const refreshList = useCallback(() => {
    setRefreshTrigger(prev => prev + 1);
  }, []);

  return (
    <>
      <Helmet>
        <title>Sub-Distributors</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              heading="Sub-Distributors"
              links={[{ name: "Sub-Distributors", href: PATH_DASHBOARD.subDistributors.list }, { name: level ? `Level ${level}` : `List` }]}
              action={
                level && level !== "5" ? (
                  <Button
                    variant="contained"
                    onClick={handleOpenModal}
                    startIcon={<Iconify icon={"eva:plus-fill"} />}
                  >
                    {`Add Level ${level} Sub-Distributor`}
                  </Button>
                ) : null
              }
            />
          </Grid>
        </Grid>

        {showAddAppUser && level && (
          <AddLeveledSubDistributor 
            parent={parent} 
            onSuccess={handleAddSuccess} 
          />
        )}

        {(!level && selectedRole.role === 'admin') && <SubDistributorList selectedRole={selectedRole} />}
        {level && (
          <LeveledSubDistributorList 
            selectedRole={selectedRole} 
            refreshTrigger={refreshTrigger}
            isLoading={isLoading}
            onRefresh={refreshList}
          />
        )}
      </Container>
    </>
  );
};

export default SubDistributorsLevelsPage;
