import { Button, CircularProgress, IconButton, Link, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import Label from "src/components/label";
import { getAccountBalance } from "../../../../api/iot-api";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Iconify from "../../../../components/iconify";
import { useSnackbar } from "../../../../components/snackbar";

IotTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

export default function IotTableRow({ row, selected, onSelectRow, onViewRow, onEditRow, onDeleteRow }) {
  const theme = useTheme();
  const { msisdn, i_account, alias } = row;
  const [ip, setIp] = useState(row.ip);
  const [voice_balance, setVoiceBalance] = useState(row.voice_balance);
  const [data_balance, setDataBalance] = useState(row.data_balance);
  const [sms_balance, setSmsBalance] = useState(row.sms_balance);
  const [package_name, setPackageName] = useState(row.package_name);
  const [package_id, setPackageId] = useState(row.package_id);
  const [updated_at, setUpdatedAt] = useState(row.updated_at);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");

  const { enqueueSnackbar } = useSnackbar();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToastOpen(false);
  };

  const handleRefreshBalances = async (event) => {
    event.stopPropagation();
    setIsLoading(true); // Start loading

    try {
      const data = await getAccountBalance(i_account);
      if (data.status_code === 1001) {
        // console.log(`data`, data);

        setVoiceBalance(data.data.voice_balance);
        setDataBalance(data.data.data_balance);
        setSmsBalance(data.data.sms_balance);
        setUpdatedAt(data.data.updated_at);
        setIp(data.data.ip);
        // Show success message
        enqueueSnackbar("Balances refreshed successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } else {
        // Show error message
        enqueueSnackbar("Failed to refresh balances", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    } catch (error) {
      // Show error message
      enqueueSnackbar("Failed to refresh balances", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <>
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <div>
              <Typography variant="subtitle2" noWrap>
                {!msisdn ? "DMA not completed" : msisdn}
              </Typography>

              <Typography variant="body2" noWrap sx={{ fontSize: "0.8rem" }}>
                Alias: {alias}
              </Typography>

              <Typography variant="body2" noWrap sx={{ fontSize: "0.8rem" }}>
                IP: {ip ? ip : "Not Connected"}
              </Typography>
            </div>
          </Stack>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {package_name}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Label
            variant={"soft"}
            color={(data_balance.toFixed(2) < 10 && "error") || (data_balance.toFixed(2) < 100 && "warning") || "success"}
            sx={{ textTransform: "capitalize", m: 0.5 }}
          >
            {data_balance.toFixed(2)} MB
          </Label>
        </TableCell>

        <TableCell align="left">
          <Label
            variant={"soft"}
            color={(voice_balance.toFixed(2) < 5 && "error") || (voice_balance.toFixed(2) < 10 && "warning") || "success"}
            sx={{ textTransform: "capitalize", m: 0.5 }}
          >
            {voice_balance.toFixed(2)} MIN
          </Label>
        </TableCell>

        <TableCell align="left">
          <Label
            variant={"soft"}
            color={(sms_balance.toFixed(2) < 5 && "error") || (sms_balance.toFixed(2) < 10 && "warning") || "success"}
            sx={{ textTransform: "capitalize", m: 0.5 }}
          >
            {sms_balance.toFixed(2)} SMS
          </Label>
        </TableCell>

        <TableCell align="left">
          <Label
            variant={"soft"}
            color={
              (moment(updated_at).fromNow().includes("month") && "error") ||
              (moment(updated_at).fromNow().includes("week") && "warning") ||
              (moment(updated_at).fromNow().includes("days") && "warning") ||
              "success"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {moment(updated_at).fromNow()}
          </Label>
        </TableCell>

        <TableCell align="right">
          <IconButton color="inherit" onClick={handleRefreshBalances}>
            {isLoading ? <CircularProgress size={20} /> : <Iconify icon="eva:refresh-fill" />}
          </IconButton>
        </TableCell>
      </Link>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
