import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Autocomplete, TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFAutocomplete({ name, label, helperText, ...other }) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
          getOptionLabel={(option) => (option ? option.label : "")}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          renderInput={(params) => <TextField label={label} error={!!error} helperText={error ? error?.message : helperText} {...params} />}
          {...other}
        />
      )}
    />
  );
}
