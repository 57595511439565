import axios from "axios";
import localStorageAvailable from "../utils/localStorageAvailable";

const storageAvailable = localStorageAvailable();

const MAX_RETRY_ATTEMPTS = 3;

export const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosAuthInstance.interceptors.request.use(
  (config) => {
    const accessToken = storageAvailable ? localStorage.getItem("accessToken") : "";
    if (!accessToken) {
      window.location.href = "/auth/login-admin";
    }
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosAuthInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error(`Error status: ${error.response.status}, Message: ${error.message}`);
      window.location.href = "/auth/login-admin";
    } else if (error.response && error.response.status >= 500) {
      console.error(`Server error: ${error.response.status}, Message: ${error.message}`);
      return Promise.reject(error);
    } else {
      let retryAttempts = 0;
      const retryRequest = () => {
        retryAttempts++;
        return axiosAuthInstance.request(error.config).catch((error) => {
          if (retryAttempts < MAX_RETRY_ATTEMPTS) {
            return retryRequest();
          }
          return Promise.reject(error);
        });
      };
      return retryRequest();
    }
  }
);

export default axiosAuthInstance;

export const axiosAuthInstanceForFormData = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 30000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

axiosAuthInstanceForFormData.interceptors.request.use(
  (config) => {
    const accessToken = storageAvailable ? localStorage.getItem("accessToken") : "";
    if (!accessToken) {
      window.location.href = "/auth/login-admin";
    }
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosAuthInstanceForFormData.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("Error occurred:", error);
    let retryAttempts = 0;
    const retryRequest = () => {
      retryAttempts++;
      if (error.response && error.response.status === 401) {
        return axiosAuthInstanceForFormData.request(error.config).catch((error) => {
          if (retryAttempts < MAX_RETRY_ATTEMPTS) {
            return retryRequest();
          }
          return Promise.reject(error);
        });
      }
      return Promise.reject(error);
    };
    return retryRequest();
  }
);
