import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, Container } from "@mui/material";
import React, { useState, useEffect } from "react";

export const ConfirmationDialog = ({ description, onClose, showConfirmPopup, onConfirm, closeIcon }) => (
    <Dialog open={showConfirmPopup} disableEscapeKeyDown>
      <DialogTitle
        sx={{
          bgcolor: "primary.main",
          color: "primary.contrastText",
          mb: 1,
          py: 2,
          pb: 1,
        }}
      >
        {description}
      </DialogTitle>
      <DialogContent
        sx={{
          mb: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}></Grid>
        </Grid>
        <DialogActions
          sx={{
            my: 0,
            justifyContent: "space-between",
          }}
        >
          <Button variant="contained" onClick={onClose} color="error">
            Cancel
          </Button>
          <Button variant="contained" onClick={onConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
