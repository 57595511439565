import { Button, InputAdornment, MenuItem, Stack, TextField } from "@mui/material";
import PropTypes from "prop-types";
// components
import Iconify from "../../../../components/iconify";
import CustomDateRangePicker from "src/components/CustomDateRangeRicker/CustomDateRangePicker";

const INPUT_WIDTH = 160;

RuleManagementToolbar.propTypes = {
  isFiltered: PropTypes.bool,
  onResetFilter: PropTypes.func,
  ruleSearchText : PropTypes.string,
  handleRuleSearchText : PropTypes.func
};

export default function RuleManagementToolbar({
  isFiltered,
  onResetFilter,
  ruleSearchText,
  handleRuleSearchText
}) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <TextField
        fullWidth
        value={ruleSearchText}
        onChange={(e) => handleRuleSearchText(e.target.value)}
        placeholder="Search Rule"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button color="error" sx={{ flexShrink: 0 }} onClick={onResetFilter} startIcon={<Iconify icon="eva:trash-2-outline" />}>
          Clear
        </Button>
      )}
    </Stack>
  );
}
