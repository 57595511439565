import PropTypes from "prop-types";
import { useRef } from "react";
import { SnackbarProvider as NotistackProvider } from "notistack";
// @mui
import { Collapse, IconButton } from "@mui/material";
//
import { useSettingsContext } from "../settings";
import Iconify from "../iconify";
//
import StyledNotistack from "./styles";

// ----------------------------------------------------------------------

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};

export default function SnackbarProvider({ children }) {
  const { themeDirection } = useSettingsContext();

  const isRTL = themeDirection === "rtl";

  const notistackRef = useRef(null);

  const onClose = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <StyledNotistack />

      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={null}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // With close as default
        action={(key) => (
          <IconButton size="medium" onClick={onClose(key)} sx={{ p: 1 }}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        )}
      >
        {children}
      </NotistackProvider>
    </>
  );
}
