import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  Grid,
  Autocomplete,
  TextField,
  Divider,
  Tabs,
  InputAdornment,
  Tab,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";

import { listAdminAppUsers, listAllAppUsers, listAllProducts, listAllVideos, listAppUsers, unsubscribeFromClevertape } from "src/api/megs-api-api";

import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
// import VideoTableRow from "./VideoTableRow";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "src/routes/paths";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import Label from "src/components/label";
import { useSnackbar } from "src/components/snackbar";
import UnsubscribeTableRow from "./UnsubscribeTableRow";
import { LoadingButton } from "@mui/lab";
export default function UnsubscribeMsisdnList({ msisdnList, handleUnsubscribedStats }) {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [msisdnListData, setMsisdnListData] = useState([]);
  const [selectedMsisdnData, setSelectedMsisdnData] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [loading, isLoading] = useState(false);
  const [error, setError] = useState(null);

  const TABLE_HEAD = [
    { id: "first_name", label: "First Name", align: "left" },
    // { id: "role", label: "Role", align: "left" },
    { id: "last_name", label: "Last Name", align: "left" },
    { id: "msisdn", label: "MSISDN", align: "left" },
    { id: "phone_number", label: "Phone Number", align: "left" },
    { id: "", label: "Unsubscribed", align: "left" },
  ];

  useEffect(() => {
    setMsisdnListData(msisdnList);
  }, [msisdnList]);

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  // if (isLoading) return <LoadingScreen />;

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  const handleSelectedMsisdn = (e, msisdn) => {
    if (!selectedMsisdnData.some((data) => data === msisdn) && e.target.checked) {
      setSelectedMsisdnData([...selectedMsisdnData, msisdn]);
    }
    if (selectedMsisdnData.some((data) => data === msisdn) && !e.target.checked) {
      let filteredMsisdn = selectedMsisdnData.filter((data) => data !== msisdn);
      setSelectedMsisdnData(filteredMsisdn);
    }
  };

  const handleUnsubscribeMsisdn = async () => {
    isLoading(true);
    const result = await unsubscribeFromClevertape(selectedMsisdnData);
    if (result.status_code === 1001) {
      handleUnsubscribedStats();
      enqueueSnackbar(result.data.message, { variant: "success" });
      setTimeout(() => {
        closeSnackbar();
      }, 2000);
    } else {
      enqueueSnackbar(result?.data?.message, { variant: "error" });
      setTimeout(() => {
        closeSnackbar();
      }, 2000);
    }
    isLoading(false);
  };

  return (
    <>
      <Card>
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={msisdnListData.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {msisdnListData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((msisdn, index) => (
                  <UnsubscribeTableRow key={index} row={msisdn} handleSelectedMsisdn={handleSelectedMsisdn} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, msisdnListData.length)} />
                <TableNoData isNotFound={msisdnListData.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={msisdnListData.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Stack spacing={3} direction="row" alignItems="flex-end" sx={{ m: 2, justifyContent: "flex-end" }}>
            <LoadingButton variant="contained" loading={loading} onClick={handleUnsubscribeMsisdn} disabled={!selectedMsisdnData.length}>
              Unsubscribe
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
