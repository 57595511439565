import { axiosAuthInstance, axiosAuthInstanceForFormData } from "./axiosAuth";
import * as Sentry from "@sentry/react";

export const validateSubscriptions = async (payload) => {
  try {
    const formDataPayload = new FormData();
    formDataPayload.append("distributor_i_customer", payload.distributor_i_customer);
    formDataPayload.append("file", payload.file);
    formDataPayload.append("requestor_id", payload.requestor_id);
    const { data } = await axiosAuthInstanceForFormData.post("/app/subscription/validateBatchFile", formDataPayload);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return {
      status_code: 500,
      message: ex.message,
    };
  }
};

export const processSubscription = async (batch_id) => {
  try {
    const formDataPayload = new FormData();
    formDataPayload.append("batch_id", batch_id);
    const { data } = await axiosAuthInstanceForFormData.post("/app/subscription/processBatchFile", formDataPayload);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return {
      status_code: 500,
      message: ex.message,
    };
  }
};

export const getDistributorSubscriptionProducts = async (distributor_i_customer) => {
  try {
    const { data } = await axiosAuthInstance.get(`/app/subscription/products?distributor_i_customer=${distributor_i_customer}`);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return {
      status_code: 500,
      message: ex.message,
    };
  }
};
