import { useLocation, matchPath } from "react-router-dom";

export default function useActiveLink(path, deep = true) {
  const { pathname } = useLocation();

  if (!path) {
    return {
      active: false,
      isExternalLink: false,
    };
  }

  const normalActive = !!matchPath({ path, end: true }, pathname);
  const deepActive = !!matchPath({ path, end: false }, pathname);

  // console.log(`useActiveLink -->
  //               path = ${path} :: pathname = ${pathname}
  //               normalActive = ${normalActive} :: deepActive = ${deepActive}
  //             `);

  return {
    active: deep ? deepActive : normalActive,
    isExternalLink: path.includes("http"),
  };
}
