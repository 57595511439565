import { Box, Link } from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { brands } from "src/utils/brands";

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  let brand = brands.find((brand) => window.location.href.includes(brand.brand_domain));
  // let brand = brands.find((brand) => "portal.tuppconnect.co.za".includes(brand.brand_domain));

  // if no match set to default
  if (!brand) {
    brand = brands[0];
  }

  const logoSrc = brand.brand_logo_portal;

  const logo = <Box component="img" src={logoSrc} sx={{ width: 96, height: 54, cursor: "pointer", ...sx }} />;

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
