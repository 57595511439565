import { Helmet } from "react-helmet-async";
// sections
import LoginAdmin from "../../sections/auth/LoginAdmin";

// ----------------------------------------------------------------------

export default function AdminLoginPage() {
  return (
    <>
      <Helmet>
        <title> Login</title>
      </Helmet>

      <LoginAdmin />
    </>
  );
}
