import PropTypes from "prop-types";
// @mui
import { Link, TableCell, TableRow, Typography } from "@mui/material";
// import check and cross icons from material icons
import { useNavigate } from "react-router-dom/dist";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "src/redux/store";
import Label from "src/components/label/Label";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

// ----------------------------------------------------------------------

BatchRequestDetailRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function BatchRequestDetailRow({ row, selected, onViewRow }) {
  const { action, billing_date, created_at, failed_reason, id, msisdn, processed_at, product_name, start_date, status, subscription_batch_request_id } = row;
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectedRole = useSelector((state) => state.auth.selectedRole);

  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  function getOrdinalNum(n) {
    let s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  return (
    <>
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {product_name}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {msisdn}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Label variant="soft" color={status === "Processed" ? "success" : status === "Failed" ? "error" : status === "New" ? "info" : null}>
            {status}
          </Label>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {billing_date !== null ? getOrdinalNum(billing_date) : "-"} of the month
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {created_at !== null
              ? new Date(created_at).toLocaleDateString("en-ZA", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {processed_at !== null
              ? new Date(processed_at).toLocaleDateString("en-ZA", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {start_date !== null
              ? new Date(start_date).toLocaleDateString("en-ZA", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : "-"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {action}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {failed_reason !== null ? failed_reason : "-"}
          </Typography>
        </TableCell>
      </Link>
    </>
  );
}
