import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
// @mui
import { Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// _mock_
import { getEscalationDetails } from "../../api/megs-api-api";
// components
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../components/settings";
// sections
import LoadingScreen from "src/components/loading-screen";
import EscalationDetails from "src/sections/@dashboard/escalations/details";

// ----------------------------------------------------------------------

export default function EscalationDetailsPage() {
  const { themeStretch } = useSettingsContext();

  const { id } = useParams();

  const [activePortStatus, setActivePortStatus] = useState({});
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [escalationData, setEscalationData] = useState([]);

  useEffect(() => {
    (async () => {
      await fetchEscalationDetails();
      setLoading(false);
    })();
  }, []);

  const fetchEscalationDetails = async () => {
    const { data } = await getEscalationDetails(id);
    setEscalationData(data);
  };

  return (
    <>
      {loading ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <>
          <Helmet>
            <title>Escalation Details</title>
          </Helmet>

          <Container maxWidth={themeStretch ? false : "xl"}>
            <CustomBreadcrumbs
              heading="Escalation"
              links={[
                { name: "Dashboard", href: PATH_DASHBOARD.root },
                {
                  name: "Escalation Details",
                  href: PATH_DASHBOARD.customerCare.escalation,
                },
                { name: `${id}` },
              ]}
            />

            <EscalationDetails escalationData={escalationData} loading={loading} />
          </Container>
        </>
      )}
    </>
  );
}
