import { Autocomplete, Button, InputAdornment, MenuItem, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";
// components
import Iconify from "../../../../components/iconify";

const INPUT_WIDTH = 160;

IotRuleTableToolbar.propTypes = {
  isFiltered: PropTypes.bool,
  onResetFilter: PropTypes.func,
  distributorsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      i_customer: PropTypes.string,
      company_name: PropTypes.string,
    })
  ),
  userRole: PropTypes.string,
  searchFilterText: PropTypes.string,
  onSearchFilterText: PropTypes.func,
  distributor: PropTypes.string,
  onDistributorChange: PropTypes.func,
};

export default function IotRuleTableToolbar({
  isFiltered,
  onResetFilter,
  distributorsOptions,
  userRole,
  searchFilterText,
  onSearchFilterText,
  distributor,
  onDistributorChange,
  onFilterChange,
}) {
  return (
    <Grid container spacing={3} alignItems="center" sx={{ px: 2.5, py: 3, display: "flex" }}>
      <Grid item sm={isFiltered ? 5 : 6} xs>
        <TextField
          value={searchFilterText}
          onChange={(e) => onFilterChange("search", e.target.value)}
          fullWidth
          placeholder="Search MSISDN ..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {userRole === "admin" && (
        <Grid item sm={isFiltered ? 5 : 6} xs={isFiltered ? 10 : 12}>
          <Autocomplete
            value={distributor}
            onChange={(e, value) => onFilterChange("distributor", value)}
            name="distributor"
            label="Select Distributor*"
            placeholder="Distributor*"
            options={distributorsOptions}
            renderInput={(params) => <TextField {...params} label="Distributor" />}
          />
        </Grid>
      )}

      {isFiltered && (
        <Grid item sm={1} xs={2}>
          <Button color="error" sx={{ flexShrink: 0, width: "20%" }} onClick={onResetFilter} startIcon={<Iconify icon="eva:trash-2-outline" />}>
            Clear
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
