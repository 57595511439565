import PropTypes from "prop-types";
// @mui
import { Link, TableRow, TableCell, Typography, Button, IconButton, Stack, Checkbox } from "@mui/material";
// import check and cross icons from material icons
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Label from "src/components/label";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "src/redux/store";
import { ConfirmationDialog } from "src/components/dialog/ConfirmationDialog";
import { deleteVideoDetails } from "src/api/megs-api-api";
import LoadingButton from "src/theme/overrides/LoadingButton";

// ----------------------------------------------------------------------

UnsubscribeTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function UnsubscribeTableRow({ row, selected, onViewRow, handleReloadVideoData, handleSelectedMsisdn }) {
  const { id, first_name, last_name, msisdn, phone_number, clevertap_messages } = row;
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const [showConfirmPopup, setShowConfirmPopup] = useState({
    show: false,
    id: null,
    role: null,
  });

  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  return (
    <>
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} underline="none">
        <TableCell>
          <Typography variant="body2" noWrap>
            {first_name}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {last_name}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {msisdn}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {phone_number}
          </Typography>
        </TableCell>

        <TableCell align="left">
          {!!clevertap_messages && (
            <Typography variant="body2" noWrap>
              <Checkbox onChange={(e) => handleSelectedMsisdn(e, msisdn !== null ? msisdn : phone_number)} />
            </Typography>
          )}
          {!clevertap_messages && (
            <IconButton>
              <Iconify icon="eva:checkmark-fill" />
            </IconButton>
          )}
        </TableCell>
      </Link>
    </>
  );
}
