import { axiosAuthInstance } from "./axiosAuth";
import * as Sentry from "@sentry/react";

const base_url = process.env.REACT_APP_API_ENDPOINT;

export const getNoticesList = async (brand_id) => {
  // console.log(`getNoticeList: ${brand_id}`);
  try {
    const payload = {
      brand_id: brand_id,
    };
    const url = `${base_url}/app/notices/customer`;
    const { data } = await axiosAuthInstance.post(url, payload);
    // console.log("getNoticeList: ", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getNoticeDetails = async (id) => {
  // console.log("getNoticeDetails");
  const body = {
    id: id,
  };
  try {
    const url = `${base_url}/app/notices/customer/details`;
    const { data } = await axiosAuthInstance.post(url, body);
    // console.log("getNoticeDetails: ", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const toggleNoticeStatus = async (id) => {
  // console.log("toggleNoticeStatus");
  try {
    const body = {
      id: id,
    };
    const url = `${base_url}/app/notices/customer/toggle`;
    const { data } = await axiosAuthInstance.post(url, body);
    // console.log("toggleNoticeStatus: ", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const updateNoticeDetails = async (body) => {
  // console.log("updateNoticeDetails");

  try {
    const url = `${base_url}/app/notices/customer`;
    const { data } = await axiosAuthInstance.put(url, body);
    // console.log("updateNoticeDetails: ", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const createNotice = async (body) => {
  // console.log("createNotice");

  try {
    const url = `${base_url}/app/notices/customer/create`;
    const { data } = await axiosAuthInstance.post(url, body);
    // console.log("createNotice: ", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getNoticeTypes = async () => {
  // console.log("getNoticeTypes");
  try {
    const url = `${base_url}/app/notices/types`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getNoticeTypes: ", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const updateNoticeOrder = async (id, index, type) => {
  // console.log("updateNoticeOrder");
  try {
    const body = {
      id: id,
      index: index,
      type: type,
    };
    const url = `${base_url}/app/notices/customer/order`;
    const data = await axiosAuthInstance.post(url, body);
    // console.log("toggleNoticeStatus: ", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};
