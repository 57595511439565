import PropTypes from "prop-types";
// @mui
import { IconButton, Link, Stack, TableCell, TableRow, Typography } from "@mui/material";
// import check and cross icons from material icons
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { deleteAppUserRole } from "src/api/megs-api-api";
import { ConfirmationDialog } from "src/components/dialog/ConfirmationDialog";
import Iconify from "src/components/iconify";
import Label from "src/components/label";
import { useSnackbar } from "src/components/snackbar";
import { useSelector } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useQuery } from "@tanstack/react-query";
import { getDistributorDetails } from "src/api/distributor-api";

// ----------------------------------------------------------------------

AppUserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function AppUserTableRow({ row, selected, onViewRow }) {
  const { id, username, name, msisdn, role, is_active, i_customer } = row;
  // console.log("row", row);
  const navigate = useNavigate();
  const [showConfirmPopup, setShowConfirmPopup] = useState({
    show: false,
    id: null,
    role: null,
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const selectedRole = useSelector((state) => state.auth.selectedRole);

  const queryInfo = useQuery({
    queryKey: ["distributorDetails", row.i_customer],
    queryFn: () => getDistributorDetails(row.i_customer),
    staleTime: 0.5 * 60 * 60, // Cache for 60 minutes
  });

  const { data: distributorDetails, isFetching } = queryInfo;

  useEffect(() => {
    if (isFetching) {
      // console.log("Fetching data from the server...");
    } else {
      // console.log("Using cached data.");
    }
  }, [isFetching]);

  // console.log("distributorDetails", distributorDetails);
  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  const handleAppUserEdit = (id) => {
    navigate(PATH_DASHBOARD.admin.edit(id));
  };

  const handleDeleteAdminUser = async (id, role) => {
    const result = await deleteAppUserRole(id, i_customer, role);
    if (result.status_code === 1001) {
      navigate(PATH_DASHBOARD.admin.appUsers);
      enqueueSnackbar("Role deleted successfully", { variant: "success" });
      setTimeout(() => {
        closeSnackbar();
      }, 2000);
    } else {
      enqueueSnackbar(result?.data?.message, { variant: "error" });
      setTimeout(() => {
        closeSnackbar();
      }, 2000);
    }
  };

  const handleAppUserDelete = (id, role) => {
    setShowConfirmPopup({
      show: true,
      id: id,
      role: role,
    });
  };

  // const handleAppUserEditPopup = (id) => {

  // };

  const handleDialogOpen = () => {
    setEditModalOpen(true);
  };

  const handleDialogClose = () => {
    setEditModalOpen(false);
  };

  return (
    <>
      {showConfirmPopup.show && (
        <ConfirmationDialog
          description={"Delete this role?"}
          showConfirmPopup={showConfirmPopup.show}
          onClose={() => setShowConfirmPopup({ show: false, id: null })}
          onConfirm={() => {
            handleDeleteAdminUser(showConfirmPopup.id, showConfirmPopup.role);
          }}
        />
      )}
      {/* <EditAdminUserDialog open={editModalOpen} handleClose={handleDialogClose} rowData={row} /> */}
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell
          onClick={(event) => {
            event.stopPropagation();
          }}
          sx={{
            cursor: "text",
          }}
        >
          <Typography variant="subtitle1" noWrap sx={{
            fontSize: "0.9rem"
          }}>
            {username}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="body2" noWrap sx={{
            fontSize: "0.9rem"
          }}>
            {name ? name : "Not Set"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap sx={{
            fontSize: "0.9rem"
          }}>
            {msisdn ? msisdn : "Not Set"}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Stack spacing={1}>
            <Label
              variant="soft"
              color={
                (role === "support_agent" && "success") ||
                (role === "distributor" && "info") ||
                (role === "sub-distributor" && "warning") ||
                ((role === "iot" || role === "referrer") && "error") ||
                "default"
              }
              sx={{ 
                m: 0.5,
                px: 1,
                width: 'fit-content'
              }}
            >
              
              <Typography variant="subtitle2" noWrap sx={{
                fontSize: "0.8rem",
              }}>
                {role ? role : "Not Set"}
              </Typography>
            </Label>
            {selectedRole.role === "admin" && (
              <Typography variant="subtitle" noWrap sx={{
                fontSize: "0.8rem",
              px: 1,
            }}>
              {truncateString(distributorDetails?.data.company_name, 25)}
            </Typography>
            )}
          </Stack>
        </TableCell>

        <TableCell align="left">
          <Typography variant="subtitle2" noWrap sx={{
            fontSize: "0.9rem"
          }}>
            {is_active ? <CheckIcon color="success" sx={{ fontSize: '1.2rem' }} /> : <ClearIcon color="error" sx={{ fontSize: '1.2rem' }} />}
          </Typography>
        </TableCell>

        <TableCell align="left">
          <IconButton
            onClick={() => {
              handleAppUserDelete(id, role);
            }}
          >
            <Iconify icon="eva:trash-2-fill" width={"1.2rem"} />
          </IconButton>
        </TableCell>
      </Link>
    </>
  );
}
