import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
// @mui
import { styled, alpha } from "@mui/material/styles";
//
import Iconify from "../iconify";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
// ----------------------------------------------------------------------

const StyledDropZone = styled("div")(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: "flex",
  flexShrink: 0,
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(0.5),
  color: theme.palette.text.disabled,
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  backgroundColor: alpha(theme.palette.grey[500], 0.08),
  "&:hover": {
    opacity: 0.72,
  },
}));

// ----------------------------------------------------------------------

MultiUploadBox.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.node,
  uploadedFile: PropTypes.arrayOf(PropTypes.object),
  maxFiles: PropTypes.number,
  onDeleteFiles: PropTypes.func,
};

export default function MultiUploadBox({ placeholder, error, disabled, sx, uploadedFile = [], maxFiles = 1, onDeleteFiles, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled,
    multiple: true,
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > maxFiles) {
        // handle error for exceeding max files limit
        console.error(`You can only upload up to ${maxFiles} files.`);
      }
      // handle file upload logic
    },
    ...other,
  });

  const [files, setFiles] = useState(uploadedFile);

  useEffect(() => {
    setFiles(uploadedFile);
  }, [uploadedFile]);

  const handleRemoveFile = (fileToRemove) => {
    const filteredFiles = files.filter((file) => file !== fileToRemove);
    setFiles(filteredFiles);
    onDeleteFiles(filteredFiles);
  };

  const isError = isDragReject || error || uploadedFile.length > maxFiles;

  return (
    <div>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isError && {
            color: "error.main",
            bgcolor: "error.lighter",
            borderColor: "error.light",
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: "none",
          }),
          ...sx,
        }}
      >
        <input {...getInputProps()} />

        {files.length > 0 ? (
          <div>{files.length === 1 ? files[0].name : `${files[0].name} and ${files.length - 1} more`}</div>
        ) : (
          placeholder || <Iconify icon="eva:cloud-upload-fill" width={28} />
        )}
      </StyledDropZone>
      <List>
        {files.map((file, index) => (
          <ListItem key={index}>
            <ListItemText primary={file.name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(file)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
