import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, Tabs, Tab, Box, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import FormProvider, { RHFTextField, RHFSelect, RHFAutocomplete, RHFDatePicker, RHFRadioGroup, RHFMultiCheckbox } from "src/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { isBefore, isSameDay } from "date-fns";
import { allocateIotRules, createIotRule, getDistributorSimCardsIot, getIotCustomers, getIotRules } from "src/api/iot-api";
import { useSnackbar } from "../../../../components/snackbar";
import { getProductType, getProductList } from "src/api/iot-api";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { getDistributorList } from "src/api/distributor-api";
import { PRE_CONFIGURED_THRESHOLD_LIMIT } from "src/config-global";
import { getDistributorSimCards } from "src/api/megs-api-api";

const IotAddSimDialog = ({ open, handleClose, handleConfirm }) => {
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer, role } = selectedRole;
  const { enqueueSnackbar } = useSnackbar();

  const [submitting, setSubmitting] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [iotRules, setIotRules] = useState([]);
  const [simCardUser,setSimCardUsers] = useState([]);

  const validationSchema = Yup.object({

    distributor: Yup.object()
      .shape({
        label: Yup.string().required("distributor is required"),
        value: Yup.string().required("distributor is required"),
      })
      .test("required", "Distributor is required", (value) => {
        return value && value.label && value.value;
      })
      .required("Distributor is required"),
      rule: Yup.object()
      .shape({
        label: Yup.string().required("Rule is required"),
        value: Yup.string().required("Rule is required"),
      })
      .test("required", "Rule is required", (value) => {
        return value && value.label && value.value;
      })
      .required("Rule is required"),
      simCard: Yup.object()
      .shape({
        label: Yup.string().required("Sim Card is required"),
        value: Yup.string().required("Sim Card is required"),
      })
      .test("required", "Sim Card is required", (value) => {
        return value && value.label && value.value;
      })
      .required("Sim Card is required"),
  });

  const defaultValues = {};
  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
    defaultValues,
    shouldFocusError: true,
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isSubmitting, dirtyFields },
    trigger,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
  } = methods;

  const values = watch();

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const ruleData = {
        i_customer: values?.distributor?.i_customer,
        i_account: values?.simCard?.i_account,
        rule_id : values?.rule?.id
    }
      const { status_code, data } = await allocateIotRules(ruleData);

      if (status_code == 1001) {
        enqueueSnackbar(data, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        handleClose()
        handleConfirm();
        reset();
      } else {
        enqueueSnackbar(data, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRuleCancle = () => {
    reset({ ...defaultValues });
    handleClose();
  };

  //* Use Effects
  useEffect(() => {
    (async () => {
      if (role == "admin") {
        const { status_code, data } = await getIotCustomers();
        if (status_code == 1001) {
          const distributorsOptions = data.map((distributor) => {
            return { ...distributor, value: distributor.i_customer, label: distributor.company_name };
          });
          setDistributors(distributorsOptions);
        }
      }
    })();
  }, []);

  useEffect(()=>{
    (async () => {
      const i_customerUser = (role == "admin") ? values?.distributor?.value : i_customer
      const { status_code, data } = await getIotRules(i_customerUser);

      if (status_code == 1001) {
        const iotRulesOptions = data.map((iotRule) => {
          return { ...iotRule, value: iotRule.id, label: iotRule.name };
        });
        setIotRules(iotRulesOptions);
      }
    })();
    (async () => {
      const i_customerUser = (role == "admin") ? values?.distributor?.value : i_customer
      const { status_code, data } = await getDistributorSimCardsIot(i_customerUser);

      if (status_code == 1001) {

        const simCardOptions = data.map((simCard) => {
          return { ...simCard, value: simCard.i_account, label: simCard.msisdn };
        });
        setSimCardUsers(simCardOptions);
      }
    })();
  },[values?.distributor])

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            bgcolor: "primary.main",
            color: "primary.contrastText",
            mb: 1,
            py: 2,
            pb: 1,
          }}
        >
          Add Sim
        </DialogTitle>
        <DialogContent sx={{ mb: 1 }}>
          <Box sx={{ pt: 4 }}>
            <Grid container spacing={2}>
              {role == "admin" && (
                <Grid item xs={12} sm={12}>
                  <RHFAutocomplete name="distributor" label="Select Distributor*" placeholder="Distributor*" options={distributors} />
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <RHFAutocomplete name="rule" label="Select Rule*" placeholder="Select Rule*" options={iotRules} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RHFAutocomplete name="simCard" label="Select Sim Card*" placeholder="Select Card*" options={simCardUser} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <DialogActions sx={{ my: 0 }}>
                  <Button variant="contained" onClick={handleRuleCancle} color="error">
                    Cancel
                  </Button>
                  <LoadingButton loading={submitting} disabled={submitting} variant="contained" onClick={onSubmit} color="primary">
                    Confirm
                  </LoadingButton>
                </DialogActions>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
};

export default IotAddSimDialog;
