import { Box, Card, CircularProgress, Table, TableBody, TableContainer, Grid, Autocomplete, TextField, Divider, Tabs, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";

import { listAdminAppUsers, listAllAppUsers, listAppUsers } from "src/api/megs-api-api";

import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import Scrollbar from "src/components/scrollbar";
import Iconify from "src/components/iconify";
import { getDistributorReportForPeriod, getDistributorReportPeriods } from "src/api/distributor-api";
import DistributorReportTableRow from "./DistributorReportTableRow";
import LoadingScreen from "src/components/loading-screen";
export default function DistributorReportList({ distributors, selectedRole, onChangeDistributor }) {
  const [distributorReports, setDistributorReports] = useState([]);
  const [distributorReportsData, setDistributorReportsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { i_customer } = selectedRole;
  const [selectedDistributor, setSelectedDistributor] = useState();
  const [datesDisabled, setDatesDisabled] = useState(false);
  const [reportDates, setReportDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  // const [selectedDistributor, setSelectedDistributor] = useState(selectedRole.role === "admin" ? null : distributors[0]);

  // const handleDistributorChange = (event, newValue) => {
  //   console.log("Distributor Values", newValue);
  //   setSelectedDistributor(newValue);
  // };

  const TABLE_HEAD = [
    { id: "name", label: "Name", align: "left" },
    { id: "date", label: "Date", align: "left" },
    { id: "total_customers", label: "Total Customers", align: "left" },
    { id: "month_customers", label: "Month Customers", align: "left" },
    { id: "total_revenue", label: "Total Revenue", align: "left" },
    { id: "total_commission", label: "Total Commission", align: "left" },
    { id: "total_commission_less_vat", label: "Total Commission less VAT", align: "left" },
    { id: "report_link", label: "Report link", align: "left" },
    { id: "po_link", label: "PO link", align: "left" },
  ];

  const TABS = [{ value: "all", label: "All", color: "primary", count: distributorReports.length }];

  const {
    dense,
    page,
    // setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  useEffect(() => {
    if (selectedDistributor) {
      const distributorId = selectedRole.role === "admin" ? selectedDistributor?.i_customer : selectedRole.i_customer;
      async function fetchDistributorReportPeriods() {
        setIsLoading(true);
        setError(null);
        try {
          const response = await getDistributorReportPeriods(distributorId);
          // console.log("response", response);
          if (response.status_code !== 1001) {
            throw new Error("Network response was not ok");
          }
          const data = await response.data.data;
          data.length && data.unshift("All");
          setReportDates(data);
        } catch (error) {
          // console.log("error", error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      }
      if (distributorId === "All") {
        setSelectedDate("All");
        setDatesDisabled(true);
      } else {
        fetchDistributorReportPeriods();
        setDatesDisabled(false);
      }
      onChangeDistributor(selectedDistributor);
    }
  }, [selectedDistributor]);

  const [search, setSearch] = useState("");

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDistributorChange = (event, newValue) => {
    setSelectedDistributor(newValue);
  };

  const handleDateChange = (event, value) => {
    setSelectedDate(value);
  };

  useEffect(() => {
    if (distributors.length) {
      if (selectedRole.role === "admin") {
        setSelectedDistributor({ company_name: "All", i_customer: "All" });
      } else {
        const distributor = distributors.find((d) => d.i_customer === selectedRole.i_customer);
        setSelectedDistributor(distributor);
      }
    }
  }, [distributors]);

  useEffect(() => {
    setSelectedDate("All");
    if (!reportDates.length) {
      setDatesDisabled(true);
    }
  }, [reportDates]);

  useEffect(() => {
    if (selectedDistributor && selectedDate) {
      async function fetchDistributorReports() {
        setIsLoading(true);
        setError(null);

        try {
          const response = await getDistributorReportForPeriod(selectedDistributor, selectedDate);
          // console.log("response", response);
          if (response.status_code !== 1001) {
            throw new Error("Network response was not ok");
          }
          const data = await response.data.data;
          setDistributorReports(data);
          setDistributorReportsData(data);
        } catch (error) {
          // console.log("error", error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      }
      fetchDistributorReports();
    }
  }, [selectedDate, selectedDistributor]);

  //   useEffect(() => {
  //     const filteredAppUsers = distributorReportsData.filter((appUser) => appUser.username.toLowerCase().includes(search.toLowerCase()));
  //     setDistributorReports([...filteredAppUsers]);
  //   }, [search]);

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.05)",
          zIndex: 9999,
        }}
      >
        <LoadingScreen />
      </Box>
    );

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        />{" "}
        <Grid container spacing={1} justifyContent="space-between">
          {selectedRole.role === "admin" && (
            <Grid item sx={{ m: 2 }} xs={6}>
              <Autocomplete
                value={selectedDistributor}
                onChange={handleDistributorChange}
                options={distributors}
                getOptionLabel={(option) => option.company_name || ""}
                renderInput={(params) => <TextField {...params} label="Select Distributor" placeholder="Select Distributor" fullWidth />}
              />
            </Grid>
          )}

          <Grid item xs={selectedRole.role === "admin" ? 5 : 3} sx={{ justifyContent: "flex-end", m: 2 }}>
            {/* {selectedDate && ( */}
            <Autocomplete
              value={selectedDate}
              onChange={handleDateChange}
              options={reportDates}
              renderInput={(params) => <TextField {...params} label="Select Date" placeholder="Select Date" fullWidth />}
              disabled={datesDisabled}
            />
            {/* )} */}
          </Grid>
        </Grid>
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={distributorReports.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {distributorReports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => (
                  <DistributorReportTableRow key={data.id} row={data} selectedDistributor={selectedDistributor} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, distributorReports.length)} />
                <TableNoData isNotFound={distributorReports.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={distributorReports.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
