import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";
import { createContext, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialize as initializeAuth, login as loginAuth, logout as logoutAuth } from "../redux/authSlice";
import { axiosApiInstance as axios } from "../api/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
import { isValidToken, setDistibutorType, setSession } from "./utils";

const BASE_API = process.env.REACT_APP_API_ENDPOINT;

export const AuthContext = createContext(null);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const state = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const storageAvailable = localStorageAvailable();

  const initializeUser = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem("accessToken") : "";
      const distributorType = storageAvailable ? localStorage.getItem("distributorType") : "";
      const username = storageAvailable ? localStorage.getItem("username") : "";
      const role = storageAvailable ? localStorage.getItem("role") : "";
      const selectedRole = storageAvailable ? localStorage.getItem("selectedRole") : "";
      const customerName = storageAvailable ? localStorage.getItem("customer_name") : "";

      if (accessToken && isValidToken(accessToken)) {
        const decodedToken = jwt_decode(accessToken);

        if (Date.now() >= decodedToken.exp * 1000) {
          console.log("Token has expired.");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          dispatch(initializeAuth({ isAuthenticated: false, user: null })); // redirect to login
          return;
        }

        if (distributorType === "admin") {
          const user = {
            username,
            type: distributorType,
            roles: ["admin", "distributor", "iot", "sub-distributor, honey"],
            selectedRole,
            customer_name: customerName,
          };
          if (!state.isAuthenticated) {
            dispatch(initializeAuth({ isAuthenticated: true, user }));
          }
        } else {
          console.log("distributorType is not admin:", distributorType);
        }
      } else {
        console.log("accessToken is not valid");
        dispatch(initializeAuth({ isAuthenticated: false, user: null }));
      }
    } catch (error) {
      console.error(error);
      dispatch(initializeAuth({ isAuthenticated: false, user: null }));
    }
  }, [storageAvailable, dispatch, state.isAuthenticated]);

  useEffect(() => {
    initializeUser();
  }, [initializeUser]);

  const singleSignOn = useCallback(
    async (encrypted_value, iv, partner) => {
      if (storageAvailable) {
        localStorage.removeItem("username");
        localStorage.removeItem("role");
        localStorage.removeItem("selectedRole");
        localStorage.removeItem("customer_name");
        localStorage.removeItem("external_id");
      }
      setSession(null);
      dispatch(logoutAuth());
      // external id decrypt
      const { data } = await axios.post(`${BASE_API}/app/sso/decrypt`, {
        encrypted_value,
        iv,
        partner,
      });


      if (data?.status_code === 1001) {
        const { external_id, i_customer, msisdn, customer_name } = data?.data;

          setDistibutorType("honey");

          const user = {
            username: external_id,
            external_id: external_id,
            type: "honey",
            roles: [
              {
                role: "honey",
                i_customer: i_customer,
                customer_name: external_id,
              },
            ],
            msisdn: msisdn,
            selectedRole: "honey",
            customer_name: external_id,
          };

          if (storageAvailable) {
            localStorage.setItem("username", user.username);
            localStorage.setItem("role", user.type);
            localStorage.setItem("selectedRole", user.selectedRole);
            localStorage.setItem("customer_name", user.customer_name);
            localStorage.setItem("external_id", external_id);
          }

        setSession(data.data.token);
          dispatch(loginAuth({ user }));
      }
    },
    [dispatch]
  );

  const login = useCallback(
    async (username, password) => {
      const { data } = await axios.post(`${BASE_API}/app/login/password`, {
        username,
        password,
      });

      if (data.status_code === 1001 && data.data && data.data.roles) {
          setDistibutorType(data.data.roles[0].role);
          const user = {
            username,
            type: data.data.roles[0].role,
            roles: data.data.roles,
            msisdn: data.data.msisdn,
            selectedRole: data.data.roles[0].role,
            customer_name: data.data.roles[0].customer_name,
            external_id: data.data.external_id,
          };

          if (storageAvailable) {
            localStorage.setItem("username", user.username);
            localStorage.setItem("role", user.type);
            localStorage.setItem("selectedRole", user.selectedRole);
            localStorage.setItem("customer_name", user.customer_name);
            localStorage.setItem("external_id", user.external_id);
          }

        setSession(data.data.tokens.token);
        dispatch(loginAuth({ user }));
      } else {
        throw new Error("Invalid username or password");
      }
    },
    [dispatch]
  );

  const logout = useCallback(() => {
    if (storageAvailable) {
      localStorage.removeItem("username");
      localStorage.removeItem("role");
      localStorage.removeItem("selectedRole");
      localStorage.removeItem("customer_name");
    }
    setSession(null);
    dispatch(logoutAuth());
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        singleSignOn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
