import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Card, Container, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, Button, Typography, InputAdornment } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { addAdminUser, addAppUser, addLeveledSubDistributor } from "src/api/megs-api-api";
import { FormProvider, RHFAutocomplete, RHFDatePicker, RHFTextField } from "src/components/hook-form";
import { useSnackbar } from "src/components/snackbar";
import * as Yup from "yup";
import { PATH_DASHBOARD } from "src/routes/paths";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import Iconify from "src/components/iconify";
import { isAfter, isBefore, isSameDay } from "date-fns";
import { format } from "date-fns";
import { createPromoRule } from "src/api/spar-api";

const ruleTextRegex = /^[A-Za-z0-9- ]+$/;

const AddNewRule = () => {
  const { themeStretch } = useSettingsContext();

  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { distributor_i_customer, id, level } = useParams();

  const [ruleType, setRuleType] = useState("Individual");
  const [individualValues, setIndividualValues] = useState([
    {
      product_name_value: null,
      barcode_number_value: null,
      minimum_quantiy_value: null,
      fields_disabled: false,
    },
  ]);

  const [variantItemValues, setVariantItemValues] = useState([
    {
      product_name_value: null,
      barcode_number_value: null,
      fields_disabled: false,
    },
    {
      product_name_value: null,
      barcode_number_value: null,
      fields_disabled: false,
    },
  ]);
  const [createRuleSchema, setCreateRuleSchema] = useState();

  const ruleTypeValues = ["Individual", "Variant", "Bundle", "Bundle Variant", "Basket Total"];

  const handleRuleChange = (e) => {
    setVariantItemValues([]);
    methods.reset(defaultValues);
    setIndividualValues([
      {
        barcode_number_value: null,
        minimum_quantiy_value: null,
        fields_disabled: false,
      },
    ]);
    setRuleType(e.target.value);
  };

  const addNewFieldsToBundelRuleSchema = () => {
    if (ruleType == "Basket Total") {
      return {
        schema: Yup.object().shape({
          rule_name: Yup.string()
            .required("Please Enter Rule Name")
            .test("rule_name", "Rule Name is not valid", (value) => ruleTextRegex.test(value)),
          rule_description: Yup.string()
            .optional()
            .test("rule_description", "Rule Description is not valid", (value) => value == "" || ruleTextRegex.test(value)),
          rewards: Yup.string()
            .required("Please Enter Rewards Value")
            .test("rewards", "Reward Value is not valid", (value) => typeof value == "string" && value >= 0),
          basket_total: Yup.string()
            .required("Please Enter Basket  Total")
            .test("basket_total", "Basket Total is not valid", (value) => typeof value == "string" && value >= 0),
          rule_start_date: Yup.date().required("Start date is required"),
          rule_end_date: Yup.date().required("End date is required").min(Yup.ref("rule_start_date"), "End date cannot be earlier than start date"),
        }),
      };
    } else if (ruleType == "Bundle" || ruleType == "Individual") {
      const repeatingBundelMinQTYFields = {};
      const repeatingBundelBarcodeNumberFields = {};
      const repeatingBundelNameFields = {};

      individualValues.forEach((x, index) => {
        repeatingBundelMinQTYFields[`minimum_quantiy_${index}`] = Yup.string()
          .required("Please Enter Minimum Quantity")
          .test(`minimum_quantiy_${index}`, "Minimum Quantity is not valid", (value) => typeof value === "string" && value >= 0)
          .test(`minimum_vs_maximum_${index}`, "Minimum Quantity should be less than or equal to Max Rule Application", function (value) {
            const { maximum_quantiy } = this.parent;
            return value && maximum_quantiy && Number(value) <= Number(maximum_quantiy);
          });
        repeatingBundelBarcodeNumberFields[`barcode_number_${index}`] = Yup.string()
          .required("Please Enter Barcode Number")
          .test(`barcode_number_${index}`, "Barcode Number is not valid", (value) => typeof value === "string" && value >= 0);
        repeatingBundelNameFields[`product_name_${index}`] = Yup.string().test(
          `product_name_${index}`,
          "Product Name is not valid",
          (value) => value == "" || ruleTextRegex.test(value)
        );
      });
      return {
        schema: Yup.object().shape({
          rule_name: Yup.string()
            .required("Please Enter Rule Name")
            .test("rule_name", "Rule Name is not valid", (value) => ruleTextRegex.test(value)),
          rule_description: Yup.string()
            .optional()
            .test("rule_description", "Rule Description is not valid", (value) => value == "" || ruleTextRegex.test(value)),
          rewards: Yup.string()
            .required("Please Enter Rewards Value")
            .test("rewards", "Reward Value is not valid", (value) => typeof value == "string" && value >= 0),
          maximum_quantiy: Yup.string()
            .required("Please Enter Max Rule Application")
            .test("maximum_quantiy", "Max Rule Application is not valid", (value) => typeof value == "string" && value >= 0),
          rule_start_date: Yup.date().required("Start date is required"),
          rule_end_date: Yup.date().required("End date is required").min(Yup.ref("rule_start_date"), "End date cannot be earlier than start date"),
          ...repeatingBundelMinQTYFields,
          ...repeatingBundelBarcodeNumberFields,
          ...repeatingBundelNameFields,
        }),
      };
    } else if (ruleType == "Variant" || ruleType == "Bundle Variant") {
      const variantBundelBarcodeNumberFields = {};
      const variantBundelNameFields = {};

      variantItemValues.forEach((x, index) => {
        variantBundelBarcodeNumberFields[`barcode_number_${index}`] = Yup.string()
          .required("Please Enter Barcode Number")
          .test(`barcode_number_${index}`, "Barcode Number is not valid", (value) => typeof value === "string" && value >= 0);
        variantBundelNameFields[`product_name_${index}`] = Yup.string().test(
          `product_name_${index}`,
          "Product Name is not valid",
          (value) => value == "" || ruleTextRegex.test(value)
        );
      });
      return {
        schema: Yup.object().shape({
          rule_name: Yup.string()
            .required("Please Enter Rule Name")
            .test("rule_name", "Rule Name is not valid", (value) => ruleTextRegex.test(value)),
          rule_description: Yup.string()
            .optional()
            .test("rule_description", "Rule Description is not valid", (value) => value == "" || ruleTextRegex.test(value)),
          rewards: Yup.string()
            .required("Please Enter Rewards Value")
            .test("rewards", "Reward Value is not valid", (value) => typeof value == "string" && value >= 0),
          maximum_quantiy: Yup.string()
            .required("Please Enter Max Rule Application")
            .test("maximum_quantiy", "Max Rule Application is not valid", (value) => typeof value == "string" && value >= 0),
          rule_start_date: Yup.date().required("Start date is required"),
          rule_end_date: Yup.date().required("End date is required").min(Yup.ref("rule_start_date"), "End date cannot be earlier than start date"),
          minimum_quantiy: Yup.string()
            .required("Please Enter Min Item Match")
            .test("minimum_quantiy", "Min Item Match is not valid", (value) => typeof value == "string" && value >= 0)
            .test(`minimum_vs_maximum`, "Min Item Match should be less than or equal to Max Rule Application", function (value) {
              const { maximum_quantiy } = this.parent;
              return value && maximum_quantiy && Number(value) <= Number(maximum_quantiy);
            }),
          ...(ruleType == "Bundle Variant" && {
            required_item_name: Yup.string().test(`required_item_name`, "Product Name is not valid", (value) => value == "" || ruleTextRegex.test(value)),
            required_item_barcode_number: Yup.string()
              .required("Please Enter Barcode Number")
              .test(`required_item_barcode_number`, "Barcode Number is not valid", (value) => typeof value === "string" && value >= 0),
          }),

          ...variantBundelBarcodeNumberFields,
          ...variantBundelNameFields,
        }),
      };
    } else {
    }
  };

  const defaultValues = {
    rule_name: "",
    rule_description: "",
    rewards: "",
    item_name: "",
    maximum_quantiy: "",
    minimum_quantiy: "",
    item_quantity: "",
    basket_name: "",
    price_total: "",
    product_name_0: "",
    barcode_number_0: "",
    minimum_quantiy_0: "",
    basket_total: "",
    required_item_name: "",
    required_item_barcode_number: "",
  };

  const methods = useForm({
    mode: "onSubmit",
    resolver: yupResolver(createRuleSchema),
    defaultValues,
    shouldFocusError: true,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    clearErrors,
    setError,
    reset,
  } = methods;

  const values = watch();

  const handleAddRule = (e) => {
    if (Object.keys(errors).length > 0 || JSON.stringify(values) === JSON.stringify(defaultValues)) {
      return;
    }
    const currentIndex = individualValues.length - 1;
    setIndividualValues((prev) => {
      const updatedValues = individualValues.map((value, valueIndex) => {
        if (valueIndex == currentIndex) {
          return {
            barcode_number_value: values[`barcode_number_${valueIndex}`],
            minimum_quantiy_value: values[`minimum_quantiy_${valueIndex}`],
            fields_disabled: true,
          };
        }
        return value;
      });
      return [
        ...updatedValues,
        {
          barcode_number_value: null,
          minimum_quantiy_value: null,
          fields_disabled: false,
        },
      ];
    });
  };

  const handleAddVariants = (e) => {
    if (Object.keys(errors).length > 0 || JSON.stringify(values) === JSON.stringify(defaultValues)) {
      return;
    }
    const currentIndex = variantItemValues.length - 1;
    setVariantItemValues((prev) => {
      const updatedValues = variantItemValues.map((value, valueIndex) => {
        if (valueIndex == currentIndex) {
          return {
            barcode_number_value: values[`barcode_number_${valueIndex}`],
            fields_disabled: true,
          };
        }
        return value;
      });
      return [
        ...updatedValues,
        {
          barcode_number_value: null,
          fields_disabled: false,
        },
      ];
    });
  };

  const handleRemoveItem = (e, index) => {
    for (let currIndex = index; currIndex < individualValues.length - 1; currIndex++) {
      setValue(`barcode_number_${currIndex}`, values[`barcode_number_${currIndex + 1}`]);
      setValue(`product_name_${currIndex}`, values[`product_name_${currIndex + 1}`]);
      setValue(`minimum_quantiy_${currIndex}`, values[`minimum_quantiy_${currIndex + 1}`]);
    }
    setValue(`barcode_number_${individualValues.length - 1}`, "");
    setValue(`product_name_${individualValues.length - 1}`, "");
    setValue(`minimum_quantiy_${individualValues.length - 1}`, "");

    setIndividualValues((prev) => {
      const filteredRemovedValue = prev.filter((value, valueIndex) => valueIndex != index);
      return filteredRemovedValue;
    });
  };

  const handleRemoveVariantItem = (e, index) => {
    for (let currIndex = index; currIndex < variantItemValues.length - 1; currIndex++) {
      setValue(`barcode_number_${currIndex}`, values[`barcode_number_${currIndex + 1}`]);
      setValue(`product_name_${currIndex}`, values[`product_name_${currIndex + 1}`]);
    }
    setValue(`barcode_number_${variantItemValues.length - 1}`, "");
    setValue(`product_name_${variantItemValues.length - 1}`, "");
    setVariantItemValues((prev) => {
      const filteredRemovedValue = prev.filter((value, valueIndex) => valueIndex != index);
      return filteredRemovedValue;
    });
  };

  useEffect(() => {
    setCreateRuleSchema(addNewFieldsToBundelRuleSchema().schema);
  }, [individualValues, variantItemValues]);

  useEffect(() => {
    ruleType === "Bundle Variant" &&
      setVariantItemValues([
        {
          product_name_value: null,
          barcode_number_value: null,
          fields_disabled: false,
        },
        {
          product_name_value: null,
          barcode_number_value: null,
          fields_disabled: false,
        },
      ]);
    ruleType === "Variant" &&
      setVariantItemValues([
        {
          product_name_value: null,
          barcode_number_value: null,
          fields_disabled: false,
        },
        {
          product_name_value: null,
          barcode_number_value: null,
          fields_disabled: false,
        },
      ]);
  }, [ruleType]);

  const onProcessSubmit = async () => {
    let newRuleRequestPayload = {};
    if (ruleType === "Basket Total") {
      newRuleRequestPayload = {
        ruleType: "basket_total",
        title: values.rule_name,
        description: values.rule_description,
        basketTotal: Number(values.basket_total),
        promoData: Number(values.rewards),
        promoStartDate: format(values.rule_start_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        promoEndDate: format(values.rule_end_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      };
    } else if (ruleType === "Individual") {
      newRuleRequestPayload = {
        ruleType: "individual",
        title: values.rule_name,
        description: values.rule_description,
        maxQty: Number(values.maximum_quantiy),
        promoData: Number(values.rewards),
        gtinBulk: [
          {
            productName: values.product_name_0,
            gtin: Number(values.barcode_number_0),
            minQty: Number(values.minimum_quantiy_0),
          },
        ],
        promoStartDate: format(values.rule_start_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        promoEndDate: format(values.rule_end_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      };
    } else if (ruleType === "Bundle") {
      const totalNumberOfBarcodes = Object.keys(values).filter((value) => value.includes("barcode_number_")).length;

      const gtinBulkData = Array.from({ length: totalNumberOfBarcodes }, (_, i) => {
        const barcode = values[`barcode_number_${i}`];
        if (barcode && barcode !== "") {
          // Check if barcode is not an empty string
          return {
            productName: values[`product_name_${i}`],
            gtin: barcode,
            minQty: Number(values[`minimum_quantiy_${i}`]),
          };
        }
        return null; // Return null if barcode is empty, to exclude it
      }).filter(Boolean);

      newRuleRequestPayload = {
        ruleType: "combo",
        title: values.rule_name,
        description: values.rule_description,
        maxQty: Number(values.maximum_quantiy),
        promoData: values.rewards,
        gtinBulk: gtinBulkData,
        promoStartDate: format(values.rule_start_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        promoEndDate: format(values.rule_end_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      };
    } else if (ruleType === "Variant" || ruleType === "Bundle Variant") {
      const totalNumberOfBarcodes = Object.keys(values).filter((value) => value.includes("barcode_number_")).length;

      const gtinBulkData = Array.from({ length: totalNumberOfBarcodes }, (_, i) => {
        const barcode = values[`barcode_number_${i}`];
        if (barcode && barcode !== "") {
          // Check if barcode is not an empty string
          return {
            productName: values[`product_name_${i}`],
            gtin: barcode,
          };
        }
        return null; // Return null if barcode is empty, to exclude it
      }).filter(Boolean);

      if (ruleType === "Bundle Variant") {
        gtinBulkData.push({
          productName: values.required_item_name,
          gtin: values.required_item_barcode_number,
          requiredComboGtin: 1,
        });
      }

      newRuleRequestPayload = {
        ruleType: ruleType === "Variant" ? "variant" : ruleType === "Bundle Variant" ? "combo_variant" : "",
        title: values.rule_name,
        description: values.rule_description,
        promoData: values.rewards,
        gtinBulk: gtinBulkData,
        minQty: Number(values.minimum_quantiy),
        maxQty: Number(values.maximum_quantiy),
        promoStartDate: format(values.rule_start_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        promoEndDate: format(values.rule_end_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      };
    } else {
    }
    try {
      const { statusCode, data , message} = await createPromoRule(newRuleRequestPayload);

      if (statusCode == 1001) {
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 1500,
        });
        navigate(PATH_DASHBOARD.ruleManagement.root);
        reset();
      } else if (statusCode == 9002) {
        enqueueSnackbar(message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          autoHideDuration: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Rule Management | megsApp </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Rule Management"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Rule Management",
              href: PATH_DASHBOARD.ruleManagement.root,
            },
            {
              name: "Add Rule",
            },
          ]}
        />
        <FormProvider methods={methods} onSubmit={handleSubmit(onProcessSubmit)}>
          <Card sx={{ p: 3, mb: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
                  Rule Type
                </Typography>
                <Select
                  id="role-type-id"
                  value={ruleType}
                  onChange={handleRuleChange}
                  placeholder="Please enter a value"
                  sx={{
                    width: 250,
                    mt: 1,
                  }}
                >
                  {ruleTypeValues.map((rule) => (
                    <MenuItem key={rule} value={rule}>
                      {rule}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {ruleType && (
              <Grid container>
                {ruleType === "Basket Total" ? (
                  <>
                    <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <RHFTextField type="text" name="rule_name" label="Rule Name*" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={8}>
                        <RHFTextField type="text" multiline={true} name="rule_description" label="Rule Description" />
                      </Grid>
                      <Grid item xs={6} sm={6} md={4}>
                        <RHFTextField type="text" name="basket_total" label="Basket Total*" />
                      </Grid>
                      <Grid item xs={6} sm={6} md={4}>
                        <RHFTextField
                          type="text"
                          name="rewards"
                          label="Reward*"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid container item columnSpacing={5} xs={12}>
                        <Grid item xs={3} md={4}>
                          <RHFDatePicker
                            format="dd/MM/yyyy"
                            name="rule_start_date"
                            label="Start On*"
                            defaultValue={"today"}
                            maxDate={"yesterday"}
                            disablePast
                            inputReadonly={true}
                          />
                        </Grid>
                        <Grid item xs={3} md={4}>
                          <RHFDatePicker format="dd/MM/yyyy" name="rule_end_date" label="End On" disablePast inputReadonly={true} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
                    <Grid item xs={12} sm={6} md={4}>
                      <RHFTextField name="rule_name" label="Rule Name*" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <RHFTextField type="text" multiline={true} name="rule_description" label="Rule Description" />
                    </Grid>
                    {(ruleType === "Variant" || ruleType === "Bundle Variant") && (
                      <Grid item xs={6} sm={6} md={4}>
                        <RHFTextField type="text" name="minimum_quantiy" label={ruleType === "Variant" ? "Min Item Match*" : "Min Secondary Item Match*"} />
                      </Grid>
                    )}
                    <Grid item xs={6} sm={6} md={4}>
                      <RHFTextField type="text" name="maximum_quantiy" label="Max Rule Application*" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      <RHFTextField
                        type="text"
                        name="rewards"
                        label="Reward*"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid container item columnSpacing={5} xs={12}>
                      <Grid item xs={3} md={4}>
                        <RHFDatePicker
                          format="dd/MM/yyyy"
                          name="rule_start_date"
                          label="Start On*"
                          defaultValue={"today"}
                          maxDate={"yesterday"}
                          disablePast
                          inputReadonly={true}
                        />
                      </Grid>
                      <Grid item xs={3} md={4}>
                        <RHFDatePicker format="dd/MM/yyyy" name="rule_end_date" label="End On*" disablePast inputReadonly={true} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Card>

          {(ruleType === "Bundle Variant" || ruleType === "Variant") && (
            <>
              {ruleType === "Bundle Variant" && (
                <Card sx={{ p: 3, mb: 3 }}>
                  <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
                    Required Item
                  </Typography>
                  <Grid
                    item
                    container
                    spacing={3}
                    xs={12}
                    sx={{
                      justifyContent: "flex-start",
                      // mt: 1,
                    }}
                  >
                    <Grid item xs={6} md={4}>
                      <RHFTextField type="text" name={`required_item_name`} label="Name" />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <RHFTextField type="text" name={`required_item_barcode_number`} label="Barcode Number*" />
                    </Grid>
                  </Grid>
                </Card>
              )}
              <Card sx={{ p: 3, mb: 3 }}>
                <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
                  Variant Items
                </Typography>
                {variantItemValues.map((value, index) => (
                  <Grid
                    item
                    container
                    spacing={3}
                    xs={12}
                    sx={{
                      justifyContent: "flex-start",
                      mb: 4,
                    }}
                    key={index}
                  >
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={4}>
                      <RHFTextField type="text" name={`product_name_${index}`} label="Name" />
                    </Grid>
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={4}>
                      <RHFTextField type="text" name={`barcode_number_${index}`} label="Barcode Number*" />
                    </Grid>
                    {variantItemValues.length > 2 && (
                      <Grid item xs={4} sx={{ alignItems: "center" }}>
                        <Button
                          onClick={(e) => handleRemoveVariantItem(e, index)}
                          variant="soft"
                          color="error"
                          startIcon={<Iconify icon="eva:trash-2-outline" />}
                        >
                          Remove
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ))}
                <Button size="small" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleSubmit(handleAddVariants)} sx={{ flexShrink: 0 }}>
                  Add Item
                </Button>
              </Card>
            </>
          )}

          {/* {ruleType !== "Basket Total" && (
            <>
              <Card sx={{ p: 3, mb: 3 }}>
                <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
                  Item Data
                </Typography>
                {individualValues.map((value, index) => (
                  <Grid
                    item
                    container
                    spacing={3}
                    xs={12}
                    sx={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      // mt: 1,
                    }}
                    key={index}
                  >
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={5}>
                      <RHFTextField type="text" name={`name_${index}`} label="Name*" />
                    </Grid>
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={5}>
                      <RHFTextField type="text" name={`barcode_number_${index}`} label="Barcode Number*" />
                    </Grid>
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={2}>
                      <RHFTextField type="text" name={`minimum_quantiy_${index}`} label="Minimum Quantity*" />
                    </Grid>
                    {ruleType === "Bundle" && individualValues.length > 1 && (
                      <Grid item xs={4} sx={{ alignItems: "center" }}>
                        <Button onClick={(e) => handleRemoveItem(e, index)} variant="soft" color="error" startIcon={<Iconify icon="eva:trash-2-outline" />}>
                          Remove
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ))}
                {ruleType === "Bundle" && (
                  <Button size="small" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleSubmit(handleAddRule)} sx={{ flexShrink: 0, mt: 2 }}>
                    Add Item
                  </Button>
                )}
              </Card>
            </>
          )} */}

          {ruleType !== "Basket Total" && ruleType !== "Variant" && ruleType !== "Bundle Variant" && (
            <>
              <Card sx={{ p: 3, mb: 3 }}>
                <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
                  Item Data
                </Typography>
                {individualValues.map((value, index) => (
                  <Grid
                    item
                    container
                    spacing={3}
                    xs={12}
                    sx={{
                      justifyContent: "flex-start",
                      mb: 4,
                    }}
                    key={index}
                  >
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={4}>
                      <RHFTextField type="text" name={`product_name_${index}`} label="Name" />
                    </Grid>
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={4}>
                      <RHFTextField type="text" name={`barcode_number_${index}`} label="Barcode Number*" />
                    </Grid>
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={4}>
                      <RHFTextField type="text" name={`minimum_quantiy_${index}`} label="Minimum Quantity*" />
                    </Grid>
                    {ruleType === "Bundle" && individualValues.length > 1 && (
                      <Grid item xs={4} sx={{ alignItems: "center" }}>
                        <Button
                          onClick={(e) => handleRemoveItem(e, index)}
                          variant="soft"
                          color="error"
                          sx={{ mb: 2 }}
                          startIcon={<Iconify icon="eva:trash-2-outline" />}
                        >
                          Remove
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ))}
                {ruleType === "Bundle" && (
                  <Button size="small" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleSubmit(handleAddRule)} sx={{ flexShrink: 0, mt: 2 }}>
                    Add Item
                  </Button>
                )}
              </Card>
            </>
          )}

          <Grid item xs={12} sm={12}>
            <Stack spacing={3} direction={"row"} alignItems="flex-end" justifyContent="flex-end" sx={{ mt: 2 }}>
              <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit(onProcessSubmit)} disabled={errors.length > 0}>
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </FormProvider>
      </Container>
    </>
  );
};

export default AddNewRule;
