import { Download } from "@mui/icons-material";
import { Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";

const ChatPage = ({ chatDetails, attachments }) => {
  let chatStyle = {
    display: "flex",
    justifyContent: "end",
  };

  let chatContentStyle = { padding: "16px", borderRadius: "10px" };

  let dateBarStyle = {
    position: "absolute",
    bottom: "0",
    color: "#6a6a6a",
    fontSize: "12px",
    fontWeight: "400",
  };

  let inDateBarStyle = {
    ...dateBarStyle,
    left: "3px",
  };

  let outDateBarStyle = {
    ...dateBarStyle,
    right: "3px",
  };

  let outChatStyle = {
    ...chatContentStyle,
    backgroundColor: "#c8fad6",
  };

  let inChatStyle = {
    ...chatContentStyle,
    backgroundColor: "#e2e7ec",
  };

  let notesChatStyle = {
    ...chatContentStyle,
    backgroundColor: "#ffe28c",
  };

  let avatarStyle = { marginRight: "10px" };

  const handleMediaDownload = (event, mediaLink) => {
    event.stopPropagation();
    if (mediaLink) {
      const link = document.createElement("a");
      link.href = mediaLink;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Grid container spacing={3}>
      {chatDetails?.map((chat) => (
        <Grid item xs={12} md={12} sx={chat?.type === "note" || chat?.attributes?.direction === "out" ? chatStyle : {}} key={chat?.id}>
          {/* <Avatar sx={avatarStyle} /> */}
          <div style={{ position: "relative", width: "600px", maxWidth: "fit-content", paddingBottom: "24px" }}>
            <Typography sx={chat?.type === "note" ? notesChatStyle : chat?.attributes?.direction === "out" ? outChatStyle : inChatStyle} variant="subtitle2">
              {chat?.type === "note" ? chat?.attributes?.body : chat?.attributes?.preview}
            </Typography>
            <Typography sx={chat?.type === "note" || chat?.attributes?.direction === "out" ? outDateBarStyle : inDateBarStyle}>
              {new Date(chat?.attributes?.createdAt).toLocaleDateString("en-ZA", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </Typography>
          </div>
        </Grid>
      ))}
      {!!attachments.length && (
        <Grid item xs={12} md={12}>
          <List>
            {attachments.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={(e) => handleMediaDownload(e, file.link)}>
                    <Download />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
      {/* <Grid item xs={12} md={12}>
        <Stack justifyContent="flex-start" direction="row" spacing={2} sx={{ mt: 3 }}>
          <LoadingButton
            size="large"
            variant="outlined"
            onClick={() => {
              console.log("Hello");
            }}
          >
            Download Attachments
          </LoadingButton>
        </Stack>
      </Grid> */}
    </Grid>
  );
};

export default ChatPage;
