import { Button, InputAdornment, MenuItem, Stack, TextField } from "@mui/material";

import PropTypes from "prop-types";
import CustomDateRangePicker from "src/components/CustomDateRangeRicker/CustomDateRangePicker";
import Iconify from "src/components/iconify";

const INPUT_WIDTH = 160;

EscalationTableToolbar.propTypes = {
  isFiltered: PropTypes.bool,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onResetFilter: PropTypes.func,
  filterService: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFilterService: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  onFilterEndDate: PropTypes.func,
  filterEndDate: PropTypes.instanceOf(Date),
  filterStartDate: PropTypes.instanceOf(Date),
  optionsService: PropTypes.arrayOf(PropTypes.object),
  selectedRole: PropTypes.string,
};

export default function EscalationTableToolbar({
  isFiltered,
  filterName,
  onFilterName,
  filterEndDate,
  filterService,
  onResetFilter,
  optionsService,
  filterStartDate,
  onFilterService,
  onFilterEndDate,
  onFilterStartDate,
  selectedRole,
}) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <TextField
        fullWidth
        select
        label="Distributor"
        value={filterService}
        onChange={onFilterService}
        disabled={selectedRole !== "admin"}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: { maxHeight: 220 },
            },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: "capitalize",
        }}
      >
        {optionsService.map((option) => (
          <MenuItem
            key={option.i_customer}
            value={option.i_customer}
            sx={{
              mx: 1,
              borderRadius: 0.75,
              typography: "body2",
              textTransform: "capitalize",
            }}
          >
            {option.company_name}
          </MenuItem>
        ))}
      </TextField>

      <CustomDateRangePicker
        filterStartDate={filterStartDate}
        filterEndDate={filterEndDate}
        onFilterStartDate={onFilterStartDate}
        onFilterEndDate={onFilterEndDate}
      />

      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        label="Search"
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          maxWidth: { xs: "100%", sm: "50%", md: "30%" },
        }}
      />

      {isFiltered && (
        <Button color="error" sx={{ flexShrink: 0 }} onClick={onResetFilter} startIcon={<Iconify icon="eva:trash-2-outline" />}>
          Clear
        </Button>
      )}
    </Stack>
  );
}
