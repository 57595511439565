import { LoadingButton } from "@mui/lab";
import { Box, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createEscalation } from "src/api/megs-api-api";
import Iconify from "src/components/iconify";
import { useSnackbar } from "src/components/snackbar";
import MultiUploadBox from "src/components/upload/MultiUploadBox";
import { PATH_DASHBOARD } from "src/routes/paths";

function CreateEscalationDetails() {
  const navigate = useNavigate();
  const [escalationTitle, setEscalationTitle] = useState(null);
  const [escalationDescription, setEscalationDescription] = useState(null);
  const [msisdn, setMsisdn] = useState(null);
  const [iccid, setIccid] = useState(null);
  const userDetails = useSelector((state) => state.auth.user);
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const { username } = userDetails;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [files, setFiles] = useState([]);

  const handleCreateEscalation = async () => {
    if (escalationTitle && escalationDescription) {
      try {
        setLoadingSubmit(true);
        const response = await createEscalation(msisdn, i_customer, username, escalationTitle, escalationDescription, files, iccid);
        if (response.status_code === 1001) {
          enqueueSnackbar("Escalation created successfully", {
            variant: "success",
          });
          setTimeout(() => {
            closeSnackbar();
          }, 8000);
          navigate(PATH_DASHBOARD.customerCare.escalation);
        } else {
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
          setTimeout(() => {
            closeSnackbar();
          }, 8000);
          console.error(response.data.message);
        }
      } catch (ex) {
        console.error(ex);
        console.error(ex.message);
      }
      setLoadingSubmit(false);
    } else {
      enqueueSnackbar("Please fill out the necessary details", {
        variant: "error",
      });
      setTimeout(() => {
        closeSnackbar();
      }, 8000);
    }
  };

  const handleDrop = useCallback((acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    if (files?.length) {
      setFiles([...files, ...acceptedFiles]);
    } else {
      setFiles(acceptedFiles);
    }
  });

  const handleDeleteFiles = (filesData) => {
    console.log("Delete", filesData);
    setFiles(filesData);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card xs={12} sx={{ py: 2, px: 3 }}>
          <Typography paragraph variant="overline" sx={{ color: "text.disabled", pb: 2 }}>
            Upload Attachments
          </Typography>
          <Box sx={{ maxWidth: "100%", pb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <MultiUploadBox
                  maxFiles={3}
                  onDrop={handleDrop}
                  onDeleteFiles={handleDeleteFiles}
                  uploadedFile={files}
                  placeholder={
                    <Stack spacing={0.5} alignItems="center" sx={{ color: "text.disabled" }}>
                      <Iconify icon="eva:cloud-upload-fill" width={40} />
                      <Typography variant="body2">Upload file</Typography>
                    </Stack>
                  }
                  sx={{
                    mb: 3,
                    py: 2.5,
                    width: "auto",
                    height: "auto",
                    borderRadius: 1.5,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card xs={12} sx={{ py: 2, px: 3 }}>
          <Typography paragraph variant="overline" sx={{ color: "text.disabled", pb: 2 }}>
            Create Escalation
          </Typography>
          <Box sx={{ maxWidth: "100%", pb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="MSISDN/Phone Number"
                  name="msisdn"
                  value={msisdn || ""}
                  onChange={(e) => setMsisdn(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="ICCID/Sim Serial Number"
                  name="iccid"
                  value={iccid || ""}
                  onChange={(e) => setIccid(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Title"
                  name="title"
                  value={escalationTitle || ""}
                  onChange={(e) => setEscalationTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Description"
                  name="description"
                  value={escalationDescription || ""}
                  onChange={(e) => setEscalationDescription(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <LoadingButton variant="contained" onClick={handleCreateEscalation} loading={loadingSubmit} sx={{ mt: 2 }}>
                  Create Escalation
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default CreateEscalationDetails;
