import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// @mui
import { Box, Button, Card, CircularProgress, Container, Divider, Tab, Table, TableBody, TableContainer, Tabs } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "../../components/snackbar";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import { useSelector } from "react-redux";
import ConfirmDialog from "../../components/confirm-dialog";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import Scrollbar from "../../components/scrollbar";
import { useSettingsContext } from "../../components/settings";
import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "../../components/table";

// sections

import LoadingScreen from "../../components/loading-screen";
import { RuleManagementToolbar } from "src/sections/@dashboard/rule-management/list";
import Iconify from "src/components/iconify";
import { getPromoRuleList } from "src/api/spar-api";
import RuleManagementTableRow from "src/sections/@dashboard/rule-management/list/RuleManagementTableRow";
import { debounce } from "src/utils/decounce";

// ----------------------------------------------------------------------

export default function RulesManagementPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    setPage,
    selected,
    setSelected,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    rowsPerPage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate", defaultRowsPerPage: 10 });

  const [tableData, setTableData] = useState([]);
  const [tableMetaData, setTableMetaData] = useState({});

  const [openConfirm, setOpenConfirm] = useState(false);

  const [ruleType, setRuleType] = useState(sessionStorage.getItem("ruleTabValue") || "individual");
  const [ruleSearchText, setRuleSearchText] = useState("");

  const [tableHead, setTableHead] = useState([
    { id: "name", label: "Name", align: "left" },
    { id: "type", label: "Rule Type", align: "left" },
    { id: "promoStartDate", label: "Promo Start Date", align: "left" },
    { id: "promoEndDate", label: "Promo End Date", align: "left" },
    { id: "status", label: "Status", align: "left" },
  ]);

  const [loading, setLoading] = useState(true);

  const denseHeight = dense ? 56 : 76;

  const isFiltered = false;

  const TABS = [
    { value: "individual", label: "Individual ", color: "primary" },
    { value: "variant", label: "Variant", color: "primary" },
    { value: "combo", label: "Bundle", color: "primary" },
    { value: "combo_variant", label: "Bundle Variant", color: "primary" },
    { value: "basket_total", label: "Basket", color: "primary" },
    { value: "inactive", label: "Inactive", color: "primary" },
  ];

  const handleRuleType = (event, newValue) => {
    setPage(0);
    setRuleType(newValue);
    setRuleSearchText("");
    sessionStorage.setItem("ruleTabValue", newValue);
  };

  const handleCreateNewRule = (e) => {
    e.preventDefault();
    navigate(PATH_DASHBOARD.ruleManagement.addRule);
  };

  const handleViewRule = (e, id) => {
    e.preventDefault();
    navigate(PATH_DASHBOARD.ruleManagement.viewRule(id));
  };

  const handleResetFilter = () => {
    // setRuleType("individual");
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue) => {
        if (searchValue?.length > 0) {
          setLoading(true);
          fetchPromoRuleList({ searchValue }).finally(() => setLoading(false));
        } else if (searchValue?.length == 0) {
          fetchPromoRuleList();
        }
      }, 300),
    []
  );

  const fetchPromoRuleList = async (filter) => {
    setLoading(true);
    try {
      const fetchRuleSearchText = filter?.searchValue ? filter.searchValue : ruleSearchText;
      const { statusCode, data } = await getPromoRuleList(ruleType, fetchRuleSearchText, page, rowsPerPage);
      if (statusCode == 1001) {
        setTableMetaData(data.meta);
        setTableData(data.data);
      } else {
        setTableMetaData({});
        setTableData([]);
      }
    } catch (err) {
      setTableData([]);
      setTableMetaData({});
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPromoRuleList();
  }, [ruleType, page, rowsPerPage]);

  useEffect(() => {
    debouncedSearch(ruleSearchText);
  }, [ruleSearchText]);

  const getRuleTypeLabel = () => {
    return TABS.find((rule) => rule.value === ruleType).label;
  };

  return (
    <>
      <>
        <Helmet>
          <title> Rule Management | megsApp </title>
        </Helmet>

        <Container maxWidth={themeStretch ? false : "xl"}>
          <CustomBreadcrumbs
            heading="Rule Management"
            links={[
              {
                name: "Dashboard",
                href: PATH_DASHBOARD.root,
              },
              {
                name: "Rule Management",
              },
            ]}
            action={
              <Button variant="contained" component={Link} onClick={handleCreateNewRule} startIcon={<Iconify icon={"eva:plus-fill"} />}>
                Add New Rule
              </Button>
            }
          />

          <Card>
            <Tabs
              value={ruleType || "individual"}
              onChange={handleRuleType}
              sx={{
                px: 2,
                bgcolor: "background.neutral",
              }}
            >
              {TABS.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>

            <Divider />

            <RuleManagementToolbar
              isFiltered={isFiltered}
              onResetFilter={handleResetFilter}
              ruleSearchText={ruleSearchText}
              handleRuleSearchText={(value) => {
                setRuleSearchText(value);
              }}
            />

            <TableContainer sx={{ position: "relative", overflow: "unset" }}>
              {loading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,0,0.05)",
                    zIndex: 9999,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <Scrollbar>
                <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                  <TableHeadCustom order={order} orderBy={orderBy} headLabel={tableHead} rowCount={setTableMetaData?.total} onSort={onSort} />

                  <TableBody>
                    {tableData.map((row) => (
                      <RuleManagementTableRow key={row.id} row={row} ruleType={getRuleTypeLabel()} handleViewRule={handleViewRule} />
                    ))}

                    <TableNoData isNotFound={tableData.length === 0} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              count={tableMetaData?.total}
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>
      </>
    </>
  );
}
