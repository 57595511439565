import PropTypes from "prop-types";
import { m } from "framer-motion";
// @mui
import { Container, Typography } from "@mui/material";
// components
import { MotionContainer, varBounce } from "../components/animate";
// assets
import { ForbiddenIllustration } from "../assets/illustrations";
//
import { useAuthContext } from "./useAuthContext";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  distributors: PropTypes.arrayOf(PropTypes.string),
};

export default function RoleBasedGuard({ hasContent, roles, distributors, children }) {
  const { user } = useAuthContext();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { customer_name } = selectedRole;

  const currentRole = user?.type;

  // Check if the user's role matches any of the allowed roles
  const hasRoleAccess = roles ? roles.includes(currentRole) : true;

  // Check if distributor access is restricted to specific distributors
  const hasDistributorAccess = currentRole === "distributor" && distributors && distributors.length > 0 ? distributors.includes(customer_name) : true;

  // Deny access if the user does not meet the role or distributor criteria
  if (!hasRoleAccess || !hasDistributorAccess) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: "center" }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>You do not have permission to access this page</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
