import { Outlet } from "react-router-dom";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
// config
import { HEADER } from "../../config-global";
// components
import Header from "./Header";
import { Stack } from "@mui/material";

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Stack
        sx={{
          py: 6,
          m: "auto",
          maxWidth: 800,
          // minHeight: "100vh",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        {/* <Header isOffset={isOffset} /> */}
      </Stack>

      <Outlet />
    </>
  );
}
