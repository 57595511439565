import PhoneInput from "mui-phone-input";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const RHFPhoneInput = React.forwardRef(({ name, helperText, ...other }, ref) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PhoneInput fullWidth disableParentheses country="za" enableSearch {...field} {...other} error={!!error} helperText={error ? error?.message : helperText} />
      )}
    />
  );
});

RHFPhoneInput.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default RHFPhoneInput;
