// brands.js
export const brands = [
  {
    brand: "megsApp",
    brand_logo: "/logo/megsapp-logo.png",
    brand_logo_portal: "/logo/megsapp_portal_updated.png",
    brand_domain: "megsapp.com",
  },
  {
    brand: "Tupp Connect",
    brand_logo: "/logo/tupp_connect_logo.jpg",
    brand_logo_portal: "/logo/tupp_connect_portal.png",
    brand_domain: "tuppconnect",
  },
  {
    brand: "FIO Connect",
    brand_logo: "/logo/fio_connect.png",
    brand_logo_portal: "/logo/fio_connect_portal.png",
    brand_domain: "fioconnect.co.za",
  },
  {
    brand: "Honey Connect",
    brand_logo: "/logo/honey_connect.png",
    brand_logo_portal: "/logo/honey_connect_portal.png",
    brand_domain: "honeyconnect.co.za",
  },
  {
    brand: "SPAR Mobile",
    brand_logo: "/logo/spar_mobile.png",
    brand_logo_portal: "/logo/spar_mobile_portal.png",
    brand_domain: "sparmobile.co.za",
  }
];
