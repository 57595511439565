import PropTypes from "prop-types";
// @mui
import { Link, TableRow, TableCell, Typography, Button, IconButton } from "@mui/material";
// import check and cross icons from material icons
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Label from "src/components/label";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "src/components/iconify";
import { ConfirmationDialog } from "src/components/dialog/ConfirmationDialog";
import { deleteAdminUser } from "src/api/megs-api-api";
import { useSnackbar } from "src/components/snackbar";

// ----------------------------------------------------------------------

LeveledSubDistributorTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  selectedRole: PropTypes.object,
  distributors: PropTypes.array,
};

export default function LeveledSubDistributorTableRow({ row, selected, onViewRow }) {
  const { id, name, contact_first_name, contact_last_name, contact_email_address, commission_ogr, commission_sales, children, level } = row;
  const navigate = useNavigate();
  const [showConfirmPopup, setShowConfirmPopup] = useState({
    show: false,
    id: null,
  });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function truncateString(str, num) {
    if (str && str.length > num) {
      return `${str.slice(0, num)}...`;
    }
    return str;
  }

  const handleAppUserEdit = (id) => {
    navigate(PATH_DASHBOARD.admin.edit(id));
  };

  return (
    <>
      <Link 
        component={TableRow} 
        hover 
        selected={selected} 
        sx={{ 
          // textDecoration: "none", 
          // cursor: children ? "pointer" : "default",
          // pointerEvents: children ? "auto" : "none",
          // '&:hover': {
          //   cursor: children ? "pointer" : "default"
          // }
          cursor: "pointer"
        }} 
        onClick={onViewRow} 
        underline="none"
      >
        <TableCell>
          <Typography variant="subtitle1" noWrap>
            {name}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {contact_first_name ? contact_first_name : "-"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {contact_last_name ? contact_last_name : "-"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {contact_email_address ? contact_email_address : "-"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {commission_ogr ? `${commission_ogr}%` : "-"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {commission_sales ? `${commission_sales}%` : "-"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2" noWrap>
            {children ? children : "-"}
          </Typography>
        </TableCell>
      </Link>
    </>
  );
}
