import { Button, CircularProgress, IconButton, Link, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import Label from "src/components/label";
import { getAccountBalance } from "../../../../api/iot-api";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Iconify from "../../../../components/iconify";
import { useSnackbar } from "../../../../components/snackbar";
import { format } from "date-fns";

IotRuleTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

export default function IotRuleTableRow({ row, selected, onSelectRow, onViewRow, onEditRow, onDeleteRow }) {
  const theme = useTheme();
  const {
    rule_id,
    distributor_i_customer,
    customer_i_customer,
    rule_name,
    rule_description,
    rule_type,
    rule_start_date,
    rule_end_date,
    rule_recurrence,
    rule_status,
    iot_company_name,
    iot_customer_msisdn,
    iot_customer_alias,
    rule_max_recharge_per_month
  } = row;


  const [openConfirm, setOpenConfirm] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  
  //* Helpers
  const formatRuleTypeString = (str) => {
    return str
      .replace(/_/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase());
  };

  const formatRuleRecurreces = () =>{
    if(rule_type == "threshold"){
      return `This rule will repeate ${rule_max_recharge_per_month} times`
    }else if(rule_type == "schedule_rule"){
      const recurrecesData = JSON.parse(rule_recurrence)
      const recurrecesType = Object.keys(recurrecesData)[0];
      if(recurrecesType == "daily"){
        return `This rule will repeat every ${recurrecesData?.daily?.recurrences} `
      }else{
        return `This rule will repeat every ${recurrecesData?.weekly?.recurrences} weeks on ${recurrecesData?.weekly?.weekdays.map((day)=>`${day} `)}`
      }
    }
    return '-'
  }
  return (
    <>
      <Link component={TableRow} hover selected={selected} sx={{ textDecoration: "none", cursor: "pointer" }} onClick={onViewRow} underline="none">
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {rule_name}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {rule_description}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {formatRuleTypeString(rule_type)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {rule_start_date || "-"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {formatRuleRecurreces()}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {iot_customer_msisdn}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {iot_company_name}
          </Typography>
        </TableCell>
      </Link>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
