import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
//
import Iconify from "../iconify";

// ----------------------------------------------------------------------

const StyledDropZone = styled("div")(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: "flex",
  flexShrink: 0,
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  margin: theme.spacing(0.5),
  color: theme.palette.text.disabled,
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  backgroundColor: alpha(theme.palette.grey[500], 0.08),
  "&:hover": {
    opacity: 0.72,
  },
}));

// ----------------------------------------------------------------------

EditUploadBox.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.node,
  uploadedFile: PropTypes.object,
  initialFileUrl: PropTypes.string,
  onFileChange: PropTypes.func,
};

export default function EditUploadBox({ placeholder, error, disabled, sx, uploadedFile, initialFileUrl, onFileChange, ...other }) {
  const [currentFile, setCurrentFile] = useState(null);

  useEffect(() => {
    if (initialFileUrl) {
      setCurrentFile({ name: initialFileUrl.split("/").pop(), url: initialFileUrl });
    }
  }, [initialFileUrl]);

  useEffect(() => {
    setCurrentFile(uploadedFile);
  }, [uploadedFile]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setCurrentFile(file);
        onFileChange(file);
      }
    },
    ...other,
  });

  const handleDelete = (e) => {
    e.stopPropagation();
    setCurrentFile(null);
    onFileChange(null);
  };

  const isError = isDragReject || error;

  return (
    <StyledDropZone
      {...getRootProps()}
      sx={{
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(isError && {
          color: "error.main",
          bgcolor: "error.lighter",
          borderColor: "error.light",
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: "none",
        }),
        ...sx,
      }}
    >
      <input {...getInputProps()} />

      {currentFile ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {currentFile.url ? (
            <a href={currentFile.url} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
              {currentFile.name}
            </a>
          ) : (
            <div>{currentFile[0].name}</div>
          )}
          <IconButton onClick={(e) => handleDelete(e)} size="small" sx={{ ml: 1 }}>
            <DeleteIcon />
          </IconButton>
        </div>
      ) : (
        placeholder || <Iconify icon="eva:cloud-upload-fill" width={28} />
      )}
    </StyledDropZone>
  );
}
